<div class="select-wrapper" [formGroup]="form" [ngClass]="{ 'is-disabled': formControl.disabled }">
  @if (label()) {
    <label [for]="controlName()">{{ label() }}</label>
  }
  <ng-select
    class="select"
    #agreeSelect
    [labelForId]="controlName()"
    [searchable]="false"
    [formControlName]="controlName()"
    [items]="options()"
    appearance="outline"
  />
</div>
<div>
  @if (formControl.invalid && formControl.touched) {
    <vk-client-validation-messages [errors]="formControl.errors"></vk-client-validation-messages>
  }
</div>
