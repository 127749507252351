import { ChangeDetectionStrategy, Component, HostBinding, input } from '@angular/core';
import { RouterLink } from '@angular/router';
import { IconComponent } from '@shared/components/icon/icon.component';

@Component({
  selector: 'vk-cta-link',
  standalone: true,
  imports: [RouterLink, IconComponent],
  templateUrl: './cta-link.component.html',
  styleUrl: './cta-link.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CtaLinkComponent {
  slug = input.required<string>();
  buttonText = input.required<string>();
  background = input<'gold' | 'gray'>('gray');

  @HostBinding('class') get bgTheme(): 'gold' | 'gray' {
    return this.background();
  }
}
