import { booleanAttribute, ChangeDetectionStrategy, Component, HostBinding, input } from '@angular/core';
@Component({
  selector: 'vk-subbrand-card',
  standalone: true,
  imports: [],
  templateUrl: './subbrand-card.component.html',
  styleUrl: './subbrand-card.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubbrandCardComponent {
  logo = input.required<string>();
  title = input<string>();
  description = input<string>();
  horizontal = input(false, { transform: booleanAttribute });
  topBorder = input(true, { transform: booleanAttribute });
  brandColor = input<string>('#000000');

  @HostBinding('style.--brand-color') get brandColorStyle(): string {
    return this.brandColor();
  }

  @HostBinding('style.flex-direction') get flexDirection(): string {
    return this.horizontal() ? 'row' : 'column';
  }

  @HostBinding('style.border-top') get border(): string {
    return this.topBorder() ? '4px solid var(--brand-color)' : '';
  }

  @HostBinding('style.padding-top') get paddingTop(): string {
    return this.topBorder() ? '32px' : '0';
  }
}
