import { CurrencyPipe, DatePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { IconComponent } from '@shared/components/icon/icon.component';
import { TicketCard } from '@shared/components/ticket-card/types/ticket-card.types';
import { BtnDirective } from '@shared/directives/button/btn.directive';
import { localeTransform } from '@shared/utils/localeInputTranformer';

@Component({
  selector: 'vk-ticket-card',
  standalone: true,
  imports: [BtnDirective, IconComponent, RouterModule, DatePipe, CurrencyPipe],
  templateUrl: './ticket-card.component.html',
  styleUrl: './ticket-card.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TicketCardComponent {
  ticketData = input.required<TicketCard>();
  locale = input.required({ transform: localeTransform, alias: 'lang' });
  dateTitle = input.required<string>();
  priceTitle = input.required<string>();
  dateFormat = input<string>('yyyy. MMMM d.');
  bgTheme = input<'gold' | 'white' | 'grey'>('white');

  @HostBinding('class') get bgThemeClass(): 'gold' | 'white' | 'grey' {
    return this.bgTheme();
  }

  openPdfUrl(url: string | null): void {
    if (!url) {
      return;
    }

    window.open(url, '_blank', 'noopener noreferrer');
  }
}
