import { FormControl } from '@angular/forms';

export type CheckboxesFormType = {
  readonly [K in string]: FormControl<boolean>;
};

export type BlockButtonIcon = {
  readonly name: string;
  readonly position: 'left' | 'right';
};

export type BlockCornerClip = 'small' | 'medium' | 'large' | 'none' | undefined;

export type BlockImage = {
  readonly url: string;
  readonly alt: string;
  readonly cornerClip?: BlockCornerClip;
  readonly maxHeight?: number;
  readonly maxWidth?: number;
};

export type BlockVideo = {
  readonly url: string;
  readonly caption?: string;
  readonly clipPathType?: BlockCornerClip;
  readonly cssHeight?: string;
  readonly cssWidth?: string;
};

export type BlockMediaPlace = 'right' | 'left' | 'top' | 'bottom';
export type BlockButtonShemeStyles = 'white' | 'gold' | 'dark';
export type BlockButtonThemeStyles = 'primary' | 'secondary' | 'tertiary';
export type BlockButtonStyle = {
  readonly theme: BlockButtonThemeStyles;
  readonly sheme: BlockButtonShemeStyles;
};

export type BlockButton = {
  readonly title: string;
  readonly url?: string;
  readonly icon?: BlockButtonIcon;
  readonly style?: BlockButtonStyle;
};

export type CtaBlockBackgrondStyles = 'dark' | 'gold';
export type InfoBackgrondStyles = 'blue-light' | 'mid-gold';
export type TextBlockBackgrondStyles = 'light' | 'gold';
export type TextColorStyles = 'dark' | 'light';

export const hexColorRegex = /^#?([0-9a-fA-F]{6}|[0-9a-fA-F]{3})$/;

export const buttonIconWithArrowRight: { readonly icon: BlockButtonIcon } = {
  icon: {
    name: 'icon-arrow-right',
    position: 'right',
  },
};

export const buttonIconWithArrowLeft: { readonly icon: BlockButtonIcon } = {
  icon: {
    name: 'icon-arrow-left',
    position: 'left',
  },
};
