<div class="card">
  @if (sight(); as sight) {
    <img class="sight-card-image card-image" [src]="sight.media.url" [alt]="sight.title" />
    <div class="card-content">
      <div>
        <h3 class="card-title">{{ sight.title }}</h3>
        @if (!listItem()) {
          <p class="card-lead">{{ sight.lead }}</p>
        }
      </div>
      @if (listItem() && sight.tags) {
        <vk-tag-list [tags]="sight.tags" card />
        <a [routerLink]="" vkBtn="tertiary"> {{ listButtonText() }}</a>
      } @else {
        <a [routerLink]="sight.url" vkBtn="tertiary">
          {{ buttonText() }}
          <vk-icon name="icon-arrow-right" />
        </a>
      }
    </div>
  }
</div>
