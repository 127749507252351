import { NgClass } from '@angular/common';
import { booleanAttribute, ChangeDetectionStrategy, Component, input, model } from '@angular/core';
import { RouterLink } from '@angular/router';
import { IconComponent } from '@shared/components/icon/icon.component';
import { BtnDirective } from '@shared/directives/button/btn.directive';

@Component({
  selector: 'vk-sidebar-item',
  standalone: true,
  imports: [RouterLink, BtnDirective, IconComponent, NgClass],
  templateUrl: './sidebar-item.component.html',
  styleUrl: './sidebar-item.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarItemComponent {
  title = input.required<string>();
  slug = input.required<string>();
  external = input(false, { transform: booleanAttribute });
  isActive = model(false);
}
