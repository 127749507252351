<div class="cta-block-text">
  <h2 class="cta-block-title block-title">{{ title() }}</h2>
  @if (description()) {
    <p class="cta-block-description is-alternative">{{ description() }}</p>
  }
</div>
<a class="cta-block-btn" [scheme]="'white'" [routerLink]="slug()" vkBtn>
  {{ buttonText() }}
  <vk-icon class="icon-arrow-right" name="icon-arrow-right" />
</a>
