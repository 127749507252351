<a class="featured-nav-card" [routerLink]="slug()" [title]="title()">
  <img class="featured-nav-card-image" [src]="imageUrl()" [alt]="title()" />
  <div class="featured-nav-card-body">
    <div class="featured-nav-card-text">
      <h3 class="featured-nav-card-title is-alternative">{{ title() }}</h3>
      @if (description()) {
        <p class="featured-nav-card-description">
          {{ description() }}
        </p>
      }
    </div>
    <vk-icon class="featured-nav-card-icon" name="icon-arrow-right" />
  </div>
</a>
