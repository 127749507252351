import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { TranslocoPipe } from '@jsverse/transloco';
import { IconComponent } from '@shared/components/icon/icon.component';

@Component({
  selector: 'vk-password-strength-validator',
  standalone: true,
  imports: [NgClass, TranslocoPipe, IconComponent],
  templateUrl: './password-strength-validator.component.html',
  styleUrl: './password-strength-validator.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PasswordStrengthValidatorComponent {
  inputErrors = input.required<ValidationErrors | null>();
}
