import { booleanAttribute, ChangeDetectionStrategy, Component, HostBinding, input } from '@angular/core';
import { TagComponent } from '@shared/components/tag/tag.component';
import { Tag } from '@shared/types/tag.types';

@Component({
  selector: 'vk-tag-list',
  standalone: true,
  imports: [TagComponent],
  templateUrl: './tag-list.component.html',
  styleUrl: './tag-list.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagListComponent {
  tags = input.required<readonly Tag[]>();
  card = input(false, { transform: booleanAttribute });

  @HostBinding('class.card-tags') get cardTags(): boolean {
    return this.card();
  }
}
