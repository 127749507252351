import { ChangeDetectionStrategy, Component, inject, input, OnInit } from '@angular/core';
import { ControlContainer, FormGroup, NonNullableFormBuilder, ReactiveFormsModule } from '@angular/forms';
import { IconComponent } from '@shared/components/icon/icon.component';

@Component({
  selector: 'vk-tag-checkbox',
  standalone: true,
  imports: [ReactiveFormsModule, IconComponent],
  templateUrl: './tag-checkbox.component.html',
  styleUrl: './tag-checkbox.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagCheckboxComponent implements OnInit {
  controlName = input.required<string>();
  label = input.required<string>();
  value = input<boolean>(false);
  iconName = input<string>('icon-close');
  iconPosition = input<'left' | 'right'>('right');

  readonly #fb = inject(NonNullableFormBuilder);
  readonly #parentContainer = inject(ControlContainer);

  get form(): FormGroup {
    return this.#parentContainer.control as FormGroup;
  }

  ngOnInit(): void {
    if (!this.form.contains(this.controlName())) {
      this.form.addControl(this.controlName(), this.#fb.control(this.value()));
    }
  }
}
