<vk-carousel-block
  class="article-card-carousel"
  [data]="articles()"
  [title]="title()"
  [description]="description()"
  [goldBackground]="goldBackground()"
>
  <ng-template [vkCarouselItem]="articles()" let-card let-index="index">
    <div class="article-card-container" [ngClass]="{ first: index === 0 }">
      <vk-article-card [article]="card" [lang]="lang()" [buttonText]="buttonText()" [showDetails]="showDetails()" [listItem]="listItem()" />
    </div>
  </ng-template>
</vk-carousel-block>
