@if (url()) {
  <a class="tag-link" [routerLink]="url()"
    >{{ title() }}
    @if (!card()) {
      <vk-icon class="icon" name="icon-arrow-right" />
    }
  </a>
} @else {
  {{ title() }}
}
