import { AfterViewInit, Directive, ElementRef, inject, Input, signal } from '@angular/core';

@Directive({
  selector: 'input[vkAutoFocus]',
  standalone: true,
})
export class AutoFocusDirective implements AfterViewInit {
  readonly #element: ElementRef<HTMLInputElement> = inject(ElementRef);
  readonly #autoFocus = signal<boolean>(true);

  @Input()
  set vkAutoFocus(value: boolean | '') {
    this.#autoFocus.set(typeof value === 'boolean' ? value : true);
  }

  ngAfterViewInit(): void {
    this.#autoFocus() ? this.#element.nativeElement.focus() : null;
  }
}
