<vk-media-with-content-block
  [image]="image()"
  [mediaPlace]="mediaPlace()"
  [mediaColumnGap]="mediaColumnGap()"
  [mediaRowGap]="mediaRowGap()"
>
  <div class="full-height-media-block-wrapper">
    <h2 class="full-height-media-block-title block-title">{{ title() }}</h2>
    <vk-default-block
      [blocks]="description()"
      [contentWidth]="contentWidth()"
      [blockGap]="blockGap()"
      [textGap]="textGap()"
    ></vk-default-block>
    <ng-content></ng-content>
  </div>
</vk-media-with-content-block>
