import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { RouterLink } from '@angular/router';
import { IconComponent } from '@shared/components/icon/icon.component';
import { BtnDirective } from '@shared/directives';
import { BlockButton, BlockImage } from '@shared/types';

@Component({
  selector: 'vk-map-block',
  standalone: true,
  imports: [RouterLink, IconComponent, BtnDirective],
  templateUrl: './map-block.component.html',
  styleUrl: './map-block.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapBlockComponent {
  title = input.required<string>();
  description = input<string | undefined>();
  button = input.required<BlockButton>();
  image = input.required<BlockImage>();
}
