import { ChangeDetectionStrategy, Component, effect, inject, OnDestroy, signal } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { MatCalendar } from '@angular/material/datepicker';
import { IconComponent } from '@shared/components/icon/icon.component';
import { startWith, Subject, takeUntil } from 'rxjs';

import { TwoCalendarDateRangeInlinePickerState } from '../../states/two-calendar-date-range-inline-picker.state';

@Component({
  selector: 'vk-left-month-selector-datepicker-header',
  standalone: true,
  imports: [IconComponent],
  templateUrl: './left-month-selector-datepicker-header.component.html',
  styleUrl: './left-month-selector-datepicker-header.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LefttMonthSelectorDatepickerHeaderComponent<D> implements OnDestroy {
  readonly #twoCalendarDateRangeInlinePickerState = inject(TwoCalendarDateRangeInlinePickerState<D>);
  readonly #calendar = inject(MatCalendar<D>);
  readonly #dateAdapter = inject(DateAdapter<D>);
  readonly #destroyed$ = new Subject<void>();

  readonly periodLabel = signal('');
  readonly previousClickDisabled = signal(false);

  constructor() {
    this.#calendar.stateChanges.pipe(startWith(null), takeUntil(this.#destroyed$)).subscribe(() => {
      this.periodLabel.set(this.#dateAdapter.format(this.#calendar.activeDate as D, 'long'));
      if (this.#calendar.minDate) {
        this.previousClickDisabled.set(
          this.#dateAdapter.format(this.#calendar.minDate as D, 'input') >=
            this.#dateAdapter.format(this.#calendar.activeDate as D, 'input')
        );
      }
    });

    effect(
      () => {
        if (this.#twoCalendarDateRangeInlinePickerState.leftCurrentDate() === null) {
          this.#twoCalendarDateRangeInlinePickerState.leftCurrentDate = this.#calendar.activeDate;
        } else {
          this.#calendar.activeDate = this.#twoCalendarDateRangeInlinePickerState.leftCurrentDate() as D;
        }
      },
      {
        allowSignalWrites: true,
      }
    );
  }

  ngOnDestroy(): void {
    this.#destroyed$.next();
    this.#destroyed$.complete();
  }

  previousClickedMonth(): void {
    this.#twoCalendarDateRangeInlinePickerState.rightCurrentDate = this.#calendar.activeDate;
    this.#twoCalendarDateRangeInlinePickerState.leftCurrentDate = this.#dateAdapter.addCalendarMonths(this.#calendar.activeDate, -1);
  }
}
