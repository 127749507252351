import { booleanAttribute, ChangeDetectionStrategy, Component, HostBinding, input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { IconComponent } from '@shared/components/icon/icon.component';
import { TagListComponent } from '@shared/components/tag-list/tag-list.component';
import { articleCardImageHeight } from '@shared/constants/article-card.constans';
import { BtnDirective } from '@shared/directives/button/btn.directive';
import { SightCard } from '@shared/types/card.types';

@Component({
  selector: 'vk-sight-card',
  standalone: true,
  imports: [RouterModule, BtnDirective, IconComponent, TagListComponent],
  templateUrl: './sight-card.component.html',
  styleUrl: './sight-card.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SightCardComponent {
  sight = input.required<SightCard>();
  buttonText = input.required<string>();
  listButtonText = input.required<string>();
  listItem = input(false, { transform: booleanAttribute });

  @HostBinding('class.sight-list-item') get listItemClass(): boolean {
    return this.listItem();
  }

  @HostBinding('style.--sight-card-list-item-height.px') get listItemHeight(): number {
    return articleCardImageHeight[this.sight().size ?? 'medium'];
  }
}
