import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, input, OnInit, signal } from '@angular/core';
import { ControlContainer, FormControl, FormGroup, NonNullableFormBuilder, ReactiveFormsModule } from '@angular/forms';
import { DialogGalleryBlockComponent } from '@shared/components/dialog-gallery-block/dialog-gallery-block.component';
import { IconComponent } from '@shared/components/icon/icon.component';
import { GalleryData } from '@shared/types';

@Component({
  selector: 'vk-dialog-hover-gallery-checkbox',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, IconComponent, DialogGalleryBlockComponent],
  templateUrl: './dialog-hover-gallery-checkbox.component.html',
  styleUrl: './dialog-hover-gallery-checkbox.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogHoverGalleryCheckboxComponent implements OnInit {
  controlName = input.required<string>();
  title = input.required<string>();
  description = input.required<string>();
  gallery = input.required<GalleryData>();
  subTitle = input<string | null>(null);
  value = input<boolean>(false);

  readonly #fb = inject(NonNullableFormBuilder);
  readonly #parentContainer = inject(ControlContainer);

  readonly hovered = signal<boolean>(false);
  readonly dialogOpened = signal<boolean>(false);

  get form(): FormGroup {
    return this.#parentContainer.control as FormGroup;
  }

  get formControl(): FormControl<string | null> {
    return this.form.get(this.controlName()) as FormControl<string | null>;
  }

  onDialogOpen($event: MouseEvent): void {
    $event.preventDefault();
    this.dialogOpened.set(true);
  }

  ngOnInit(): void {
    if (!this.form.contains(this.controlName())) {
      this.form.addControl(this.controlName(), this.#fb.control(this.value()));
    }
  }
}
