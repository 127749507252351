<ng-content></ng-content>
<vk-dialog [(opened)]="dialogOpened">
  <section class="gallery-block">
    <vk-carousel class="gallery-block-carousel" #carosel [data]="gallery()" hasFirstItemOffset hasLastItemOffset>
      <ng-template [vkCarouselItem]="gallery()" let-img let-index="index">
        <img class="gallery-block-image" [src]="img.url" [alt]="img.alt" (load)="carosel.initializeSwiper()" />
      </ng-template>
    </vk-carousel>
  </section>
</vk-dialog>
