import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, input } from '@angular/core';
import { RouterLink } from '@angular/router';
import { IconComponent } from '@shared/components/icon/icon.component';
import { VideoComponent } from '@shared/components/video/video.component';
import { BtnDirective } from '@shared/directives';
import { BlockImage, BlockMediaPlace, BlockVideo } from '@shared/types';

@Component({
  selector: 'vk-media-with-content-block',
  standalone: true,
  imports: [NgClass, RouterLink, IconComponent, VideoComponent, BtnDirective],
  templateUrl: './media-with-content-block.component.html',
  styleUrl: './media-with-content-block.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MediaWithContentBlockComponent {
  image = input<BlockImage | undefined>(undefined);
  video = input<BlockVideo | undefined>(undefined);
  mediaPlace = input<BlockMediaPlace>('right');
  mediaColumnGap = input<number>(120);
  mediaRowGap = input<number>(24);

  @HostBinding('style.--media-with-content-block-flex-direction') get flexDirectionStyle():
    | 'row'
    | 'row-reverse'
    | 'column'
    | 'column-reverse' {
    switch (this.mediaPlace()) {
      case 'right':
        return 'row';
      case 'left':
        return 'row-reverse';
      case 'top':
        return 'column-reverse';
      case 'bottom':
        return 'column';
    }
  }

  @HostBinding('style.--media-with-content-block-image-max-height') get imageMaxHeightStyle(): string {
    return this.image() && this.image()?.maxHeight ? `${this.image()!.maxHeight}px` : '100%';
  }

  @HostBinding('style.--media-with-content-block-image-max-width') get imageMaxWidthStyle(): string {
    return this.image() && this.image()?.maxWidth ? `${this.image()!.maxWidth}px` : '100%';
  }

  @HostBinding('style.--media-with-content-block-content-column-gap') get mediaColumnGapStyle(): string {
    return `${this.mediaColumnGap()}px`;
  }

  @HostBinding('style.--media-with-content-block-content-row-gap') get mediaRowGapStyle(): string {
    return `${this.mediaRowGap()}px`;
  }
}
