<div class="hover-modal-gallery-checkbox-container" (mouseover)="hovered.set(true)" (mouseout)="hovered.set(false)">
  <div class="hover-modal-gallery-checkbox-wrapper">
    <label class="hover-modal-gallery-checkbox-label" [formGroup]="form" [ngClass]="{ 'is-disabled': formControl.disabled }">
      <input class="hover-modal-gallery-checkbox" [id]="controlName()" [formControlName]="controlName()" type="checkbox" />
      <div class="hover-modal-gallery-checkbox-text">
        <p class="hover-modal-gallery-checkbox-text-label">{{ title() }}</p>
        @if (subTitle()) {
          <p class="hover-modal-gallery-checkbox-text-title">{{ subTitle() }}</p>
        }
        <p class="hover-modal-gallery-checkbox-text-desc">{{ description() }}</p>
      </div>
    </label>
    <vk-dialog-gallery-block [(dialogOpened)]="dialogOpened" [gallery]="gallery()">
      <div class="gallery-block-image-thumbnail-wrapper">
        <img class="gallery-block-image-thumbnail" [src]="gallery()[0].url" [alt]="gallery()[0].alt" />
        <div
          class="gallery-block-image-thumbnail-hover-content"
          [ngClass]="{ hovered: hovered() || dialogOpened() }"
          (click)="onDialogOpen($event)"
        >
          <vk-icon class="gallery-block-image-thumbnail-hover-content-icon" name="icon-gallery-horizontal-end" />
          <p class="gallery-block-image-thumbnail-hover-content-text">Galéria</p>
        </div>
      </div>
    </vk-dialog-gallery-block>
  </div>
  <ng-content></ng-content>
</div>
