import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, input, model } from '@angular/core';
import { CarouselComponent } from '@shared/components/carousel/carousel.component';
import { CarouselItemDirective } from '@shared/components/carousel/directives/carousel-item.directive';
import { DialogComponent } from '@shared/components/dialog/dialog.component';
import { IconComponent } from '@shared/components/icon/icon.component';
import { GalleryData } from '@shared/types';

@Component({
  selector: 'vk-dialog-gallery-block',
  standalone: true,
  imports: [CommonModule, DialogComponent, CarouselComponent, CarouselItemDirective, IconComponent],
  templateUrl: './dialog-gallery-block.component.html',
  styleUrl: './dialog-gallery-block.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogGalleryBlockComponent {
  gallery = input.required<GalleryData>();
  dialogOpened = model<boolean>(false);
}
