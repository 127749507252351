import { animate, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, Component, HostBinding, inject, input } from '@angular/core';
import { BreakpointState } from '@core/states/breakpoint/breakpoint.state';
import { LocalizeState } from '@core/states/localize/localize.state';
import { ProgramsState } from '@core/states/programs/programs.state';
import { FULL_WIDTH } from '@shared/constants';

import { ProgramCardCarouselComponent } from '../program-card-carousel.component';

@Component({
  selector: 'vk-recommended-program-card-carousel-wrapper',
  standalone: true,
  imports: [ProgramCardCarouselComponent],
  templateUrl: './recommended-program-card-carousel-wrapper.component.html',
  styleUrl: './recommended-program-card-carousel-wrapper.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('fade', [
      state(
        'void',
        style({
          opacity: 0.7,
        })
      ),
      state(
        '*',
        style({
          opacity: 1,
        })
      ),
      transition('* => void', [animate('600ms ease-out')]),
      transition('void => *', [animate('600ms ease-in')]),
    ]),
  ],
})
export class RecommendedProgramCardCarouselWrapperComponent {
  @HostBinding(FULL_WIDTH) fullWidth = true;

  title = input.required<string>();
  buttonText = input.required<string>();

  readonly breakpointState = inject(BreakpointState);
  readonly localizeState = inject(LocalizeState);
  readonly programsState = inject(ProgramsState);

  constructor() {
    this.programsState.fetchProgramRecommendations();
  }
}
