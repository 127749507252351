import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { IconComponent } from '@shared/components/icon/icon.component';
import { BtnDirective } from '@shared/directives';

@Component({
  selector: 'vk-newsletter-block',
  templateUrl: './newsletter-block.component.html',
  styleUrls: ['./newsletter-block.component.scss'],
  standalone: true,
  imports: [CommonModule, BtnDirective, IconComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewsletterBlockComponent {
  title = input.required<string>();
  description = input.required<string>();
  buttonText = input.required<string>();
}
