import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { CarouselComponent, CarouselItemDirective } from '@shared/components';
import { GalleryData } from '@shared/types';

@Component({
  selector: 'vk-gallery-block',
  standalone: true,
  imports: [CarouselComponent, CarouselItemDirective],
  templateUrl: './gallery-block.component.html',
  styleUrl: './gallery-block.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GalleryBlockComponent {
  gallery = input<GalleryData>([]);
}
