import { CommonModule } from '@angular/common';
import { booleanAttribute, ChangeDetectionStrategy, Component, input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslocoPipe } from '@jsverse/transloco';
import { CarouselItemDirective } from '@shared/components/carousel/directives/carousel-item.directive';
import { CarouselBlockComponent } from '@shared/components/carousel-block/carousel-block.component';
import { IconComponent } from '@shared/components/icon/icon.component';
import { ProgramCardComponent } from '@shared/components/program-card/program-card.component';
import { BtnDirective } from '@shared/directives';
import { ProgramCard } from '@shared/types/card.types';
import { AvailableLangs } from 'src/environments';

@Component({
  selector: 'vk-program-card-carousel',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    TranslocoPipe,
    BtnDirective,
    IconComponent,
    CarouselBlockComponent,
    CarouselItemDirective,
    ProgramCardComponent,
  ],
  templateUrl: './program-card-carousel.component.html',
  styleUrl: './program-card-carousel.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProgramCardCarouselComponent {
  title = input.required<string>();
  programs = input.required<readonly ProgramCard[]>();
  lang = input.required<AvailableLangs>();
  buttonText = input.required<string>();
  fullWidthCard = input(false, { transform: booleanAttribute });
}
