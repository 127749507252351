import { CommonModule, NgClass } from '@angular/common';
import {
  afterNextRender,
  AfterRenderPhase,
  booleanAttribute,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  contentChild,
  CUSTOM_ELEMENTS_SCHEMA,
  effect,
  ElementRef,
  inject,
  Injector,
  input,
  TemplateRef,
  viewChild,
} from '@angular/core';
import { CarouselItemDirective } from '@shared/components/carousel/directives/carousel-item.directive';
import { IconComponent } from '@shared/components/icon/icon.component';
import { BtnDirective } from '@shared/directives/button/btn.directive';
import { register, SwiperContainer } from 'swiper/element';
import { Navigation, Scrollbar } from 'swiper/modules';

@Component({
  selector: 'vk-carousel',
  standalone: true,
  imports: [CarouselItemDirective, CommonModule, BtnDirective, IconComponent, NgClass],
  templateUrl: './carousel.component.html',
  styleUrl: './carousel.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CarouselComponent<TData extends Object> {
  data = input<readonly TData[]>([]);
  swiperParams = input<Partial<SwiperContainer>>({});
  itemTemplate = input<TemplateRef<unknown>>();
  hasFirstItemOffset = input(false, { transform: booleanAttribute });
  hasLastItemOffset = input(false, { transform: booleanAttribute });

  readonly itemContentChildTemplate = contentChild(CarouselItemDirective, { read: TemplateRef });
  readonly swiperContainer = viewChild.required<ElementRef<SwiperContainer>>('swiper');
  readonly swiperScrollbar = viewChild.required<ElementRef<HTMLDivElement>>('scrollbar');

  readonly #cdr = inject(ChangeDetectorRef);
  readonly #injector = inject(Injector);

  constructor() {
    register();

    afterNextRender(
      () => {
        this.initializeSwiper();
        effect(
          () => {
            this.initializeSwiper();
          },
          { injector: this.#injector }
        );
      },
      { phase: AfterRenderPhase.Write }
    );
  }

  initializeSwiper(): void {
    const swiperEl = this.swiperContainer().nativeElement;
    const swiperDefaultParams: Partial<SwiperContainer> = {
      modules: [Navigation, Scrollbar],
      slidesPerView: 'auto',
      spaceBetween: 24,
      scrollbar: {
        draggable: true,
        el: this.swiperScrollbar().nativeElement,
      },
      cssMode: true,
      observer: true,
      observeParents: true,
      observeSlideChildren: true,
      breakpoints: {
        576: {
          spaceBetween: 32,
        },
        768: {
          spaceBetween: 40,
        },
      },
    };

    Object.assign(swiperEl, swiperDefaultParams, this.swiperParams());

    swiperEl.initialize();
    this.#cdr.detectChanges();
  }
}
