import { NgClass, TitleCasePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { TranslocoPipe } from '@jsverse/transloco';
import { IconComponent } from '@shared/components/icon/icon.component';

@Component({
  selector: 'vk-social-btn',
  standalone: true,
  imports: [IconComponent, NgClass, TitleCasePipe, TranslocoPipe],
  templateUrl: './social-btn.component.html',
  styleUrl: './social-btn.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SocialBtnComponent {
  type = input.required<'google' | 'facebook'>();
  mode = input.required<'registration' | 'login'>();
  regButtonText = input.required<string>();
  loginButtonText = input.required<string>();
  profileButtonText = input.required<string>();
}
