<ol class="masonry-list-container">
  @for (item of items(); track $index) {
    <li class="masonry-list-item" #listItem [style]="'grid-row: span ' + getRowSpan(elements()[$index].nativeElement.clientHeight)">
      <ng-container *ngTemplateOutlet="itemContentChildTemplate() || defaultItemTemplate; context: { $implicit: item }" />
    </li>
  }
</ol>

<ng-template #defaultItemTemplate>
  <p data-playwright-error>Provide template!</p>
</ng-template>
