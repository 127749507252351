import { computed, inject, Injectable, Signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { patchState, signalState } from '@ngrx/signals';
import { UtilService } from '@shared/services';
import { BehaviorSubject, debounceTime, distinctUntilChanged, fromEvent, map, startWith, tap } from 'rxjs';

export type Breakpoint = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
export type ScreenSize = 'Mobile' | 'Tablet' | 'Desktop';
export const BREAKPOINTS: Record<Breakpoint, number> = {
  xs: 0,
  sm: 480,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1440,
};

type BreakpointStateType = {
  readonly breakpoint: Breakpoint;
  readonly isMobile: boolean;
  readonly isTablet: boolean;
  readonly isDesktop: boolean;
  readonly screenSize: ScreenSize;
};

const initialState: BreakpointStateType = {
  breakpoint: 'xs',
  isMobile: true,
  isTablet: false,
  isDesktop: false,
  screenSize: 'Mobile',
};

@Injectable({
  providedIn: 'root',
})
export class BreakpointState {
  readonly #breakpointState = signalState<BreakpointStateType>(initialState);
  readonly #utilService: UtilService = inject(UtilService);
  readonly #breakpoint$ = this.#utilService.isBrowser()
    ? fromEvent(window, 'resize').pipe(
        debounceTime(100),
        distinctUntilChanged(),
        map(() => this.#getCurrentBreakpointByWindowInnerWidth()),
        startWith(this.#getCurrentBreakpointByWindowInnerWidth())
      )
    : new BehaviorSubject<Breakpoint>('xs');
  readonly #breakpoint = toSignal(
    this.#breakpoint$.pipe(
      tap((breakpoint) => {
        patchState(this.#breakpointState, {
          breakpoint,
          isMobile: breakpoint === 'xs' || breakpoint === 'sm',
          isTablet: breakpoint === 'md' || breakpoint === 'lg',
          isDesktop: breakpoint === 'xl' || breakpoint === 'xxl',
          screenSize:
            breakpoint === 'xs' || breakpoint === 'sm' ? 'Mobile' : breakpoint === 'md' || breakpoint === 'lg' ? 'Tablet' : 'Desktop',
        });
      })
    )
  );

  readonly isXsBreakpoint: Signal<boolean> = computed(() => this.#breakpointState().breakpoint === 'xs');

  readonly isSmBreakpoint: Signal<boolean> = computed(() => this.#breakpointState().breakpoint === 'sm');

  readonly isMdBreakpoint: Signal<boolean> = computed(() => this.#breakpointState().breakpoint === 'md');

  readonly isLgBreakpoint: Signal<boolean> = computed(() => this.#breakpointState().breakpoint === 'lg');

  readonly isXlBreakpoint: Signal<boolean> = computed(() => this.#breakpointState().breakpoint === 'xl');

  readonly isXxlBreakpoint: Signal<boolean> = computed(() => this.#breakpointState().breakpoint === 'xxl');

  readonly isMobile: Signal<boolean> = this.#breakpointState.isMobile;

  readonly isTablet: Signal<boolean> = this.#breakpointState.isTablet;

  readonly isDesktop: Signal<boolean> = this.#breakpointState.isDesktop;

  readonly screenSize: Signal<ScreenSize> = this.#breakpointState.screenSize;

  readonly breakpoint: Signal<Breakpoint> = this.#breakpointState.breakpoint;

  constructor() {
    this.#breakpoint();
  }

  #getCurrentBreakpointByWindowInnerWidth(): Breakpoint {
    return Object.entries(BREAKPOINTS)
      .filter(([, breakpoint]) => window.innerWidth >= breakpoint)
      .reverse()[0][0] as Breakpoint;
  }
}
