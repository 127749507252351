@if (data(); as data) {
  <div class="toast" [ngClass]="data.type" cdkTrapFocus>
    <div class="toast-content">
      <h3 class="toast-title">{{ data.title }}</h3>
      <p class="toast-description">{{ data.description }}</p>
    </div>

    <button class="toast-close-button" [attr.aria-label]="ariaCloseLabel()" (click)="onClickClose()">
      <vk-icon class="toast-icon" [name]="'icon-close'"></vk-icon>
    </button>
  </div>
}
