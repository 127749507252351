import { ContentPageMeta } from '@trendency/simple/page/types/content-page.types';
import { AvailableLangs } from 'src/environments';

import { DefaultContent } from './default.types';
import { BlockImage, BlockVideo } from './form.types';
import { GalleryData } from './gallery.types';
import { InfoBlock, Media } from './response.types';

/* eslint-disable functional/prefer-readonly-type */
export enum VenueRentalFormStateEnum {
  VENUE_SELECTION = 'venue-selection',
  RENTAL_PERIOD = 'rental-period',
  EVENT_DATA = 'event-data',
  EVENT_PARTICIPANTS = 'event-participants',
  REQUESTING_SERVICES = 'requesting-services',
  CONTACT_DETAILS = 'contact-details',
  COMMENT = 'comment',
  TERMS_AND_CONDITIONS = 'terms-and-conditions',
  SUBSCRIBE_NEWSLETTER = 'subscribe-newsletter',
  THANK = 'thank',
}

export type VenueNumberOfParticipantType = '0-50' | '50-100' | '100-200' | '200-500' | '500-1000' | '1000+';
export type VenueEventAvailabilityType = 'open' | 'closed';

export type VenueNumberOfParticipantOptionsType = { readonly [key in VenueNumberOfParticipantType]: string };
export type VenueEventAvailabilitiesType = { readonly [key in VenueEventAvailabilityType]: string };

export type VenueLocalizedNumberOfParticipantOptionsType = { readonly [key in AvailableLangs]: VenueNumberOfParticipantOptionsType };
export type VenueLocalizedEventAvailabilitiesType = { readonly [key in AvailableLangs]: VenueEventAvailabilitiesType };
export type VenueLocalizedVenuePlaceSlugsType = { [key in AvailableLangs]: { [key: string]: VenuePlaceType } };

export type VenueInitialsType = {
  numberOfParticipantOptions: VenueLocalizedNumberOfParticipantOptionsType;
  eventAvailabilities: VenueLocalizedEventAvailabilitiesType;
};
export type VenueInitialsResponse = {
  peopleCount: VenueLocalizedNumberOfParticipantOptionsType;
  availability: VenueLocalizedEventAvailabilitiesType;
};

export type NewsletterSegmentsInfoType = {
  id: string;
  name: string;
  description: string;
  isSubscribed: boolean;
};

export type NewsletterSegmentsInfoResponse = {
  id: string;
  name: string;
  description: string;
  isSubscribed: boolean;
};

export type SubscribeToNewslettersType = {
  email: string;
  firstName: string;
  lastName: string;
  newsletters: ReadonlyArray<string>;
};

export type VenueUtilizationDetailsResponse = {
  meta: ContentPageMeta;
  landingPage: {
    title: string;
    lead: string;
    mediaCollection: ReadonlyArray<Media>;
  };
  infoBox: {
    infoBlocks: ReadonlyArray<InfoBlock>;
    details: { blocks: ReadonlyArray<DefaultContent> };
  };
  termsAndConditions: {
    blocks: ReadonlyArray<DefaultContent>;
  };
};

export type VenueUtilizationDetailsType = {
  landing: {
    title: string;
    lead: string;
    videoCollection: ReadonlyArray<BlockVideo>;
  };
  infoBox: {
    infoBlocks: ReadonlyArray<InfoBlock>;
    contentBlocks: ReadonlyArray<DefaultContent>;
  };
  termsAndConditions: {
    contentBlocks: ReadonlyArray<DefaultContent>;
  };
};

export type VenueResponse = {
  id: string;
  type: VenuePlaceType;
  title: string;
  subTitle: string | null;
  description: string;
  content: { blocks: ReadonlyArray<DefaultContent> };
  media: Media;
  mapMedia: Media | null;
  mediaCollection: ReadonlyArray<Media>;
  services: ReadonlyArray<VenueServiceType>;
};

export type VenueType = {
  id: string;
  type: VenuePlaceType;
  title: string;
  subTitle: string | null;
  description: string;
  content: ReadonlyArray<DefaultContent>;
  media: BlockImage;
  mapMedia: BlockImage | null;
  mediaCollection: GalleryData;
  services: ReadonlyArray<VenueServiceType>;
};

export type VenuePlaceType = 'indoor' | 'outdoor';

export type VenueReservedPeriod = {
  startDate: Date;
  endDate: Date;
};

export type VenueServiceType = {
  id: string;
  label: string;
};

export type VenueReservedPeriodsResponse = ReadonlyArray<{
  readonly venueId: VenueType['id'];
  readonly periods: ReadonlyArray<{
    readonly startDate: Date;
    readonly endDate: Date;
  }>;
}>;

export type VenueReservedPeriods = ReadonlyArray<{
  readonly venueId: VenueType['id'];
  readonly periods: ReadonlyArray<{
    readonly startDate: Date;
    readonly endDate: Date;
  }>;
}>;

export type VenueRentalStateType = {
  readonly activeFormState: VenueRentalFormStateEnum | null;
  readonly spectatorFormState: VenueRentalFormStateEnum | null;
  readonly visitedFormStates: ReadonlyArray<VenueRentalFormStateEnum>;
  readonly availableVenues: VenueType[];
  readonly reservedPeriodsByVenue: VenueReservedPeriods;
  readonly venueRentalData: {
    readonly venues: ReadonlyArray<VenueType['id']>;
    readonly period: {
      readonly startDate: Date | null;
      readonly endDate: Date | null;
    };
    readonly eventData: {
      readonly name: string | null;
      readonly numberOfParticipant: VenueNumberOfParticipantType | null;
      readonly availabilityType: VenueEventAvailabilityType | null;
    };
    readonly participants: ReadonlyArray<string>;
    readonly services: ReadonlyArray<VenueServiceType['id']>;
    readonly contactDetails: {
      readonly name: {
        readonly title: string | null;
        readonly firstName: string | null;
        readonly lastName: string | null;
      };
      readonly email: string | null;
      readonly phone: string | null;
      readonly organizationName: string | null;
    };
    readonly comment: string | null;
  };
  readonly newsletterSubscribe: SubscribeToNewslettersType | null;
};
