<div class="textarea-wrapper" [formGroup]="form" [ngClass]="{ 'is-disabled': formControl.disabled }">
  @if (label()) {
    <label class="label" [for]="controlName()">{{ label() }}</label>
  }
  <textarea
    class="textarea"
    [id]="controlName()"
    [value]="value()"
    [rows]="rows()"
    [placeholder]="placeholder()"
    [formControlName]="controlName()"
  ></textarea>
  <div>
    @if (formControl.invalid && formControl.touched) {
      <vk-client-validation-messages [errors]="formControl.errors"></vk-client-validation-messages>
    }
  </div>
</div>
