import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { RouterLink } from '@angular/router';
import { IconComponent } from '@shared/components/icon/icon.component';
import { BtnDirective } from '@shared/directives/button/btn.directive';

@Component({
  selector: 'vk-cta-block',
  standalone: true,
  imports: [RouterLink, IconComponent, BtnDirective],
  templateUrl: './cta-block.component.html',
  styleUrl: './cta-block.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CtaBlockComponent {
  title = input.required<string>();
  buttonText = input.required<string>();
  slug = input.required<string>();
  description = input<string | undefined>();
}
