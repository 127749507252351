import { DOCUMENT } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, inject, input, OnDestroy, Renderer2 } from '@angular/core';

@Component({
  selector: 'vk-facebook-post-embed',
  standalone: true,
  imports: [],
  templateUrl: './facebook-post-embed.component.html',
  styleUrl: './facebook-post-embed.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FacebookPostEmbedComponent implements AfterViewInit, OnDestroy {
  // 'https://www.facebook.com/konyvmolyokbirodalma/posts/pfbid0vnzSyqiVirmRNKyDVUKpPNGNBH1xGKyXkfm1LHtueMy5uaGXH9JhUoyZSNoz1Zhrl'
  facebookPostURL = input<string>('https://www.facebook.com/share/LavYH4AnVafDmDyc/');
  readonly #renderer = inject(Renderer2);
  readonly #document = inject(DOCUMENT);

  #scriptElement: HTMLScriptElement;

  ngAfterViewInit(): void {
    this.#scriptElement = this.#renderer.createElement('script');
    this.#scriptElement.src = 'https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v3.2';
    this.#scriptElement.async = true;
    this.#scriptElement.defer = true;
    this.#renderer.appendChild(this.#document.body, this.#scriptElement);
  }

  ngOnDestroy(): void {
    this.#renderer.removeChild(this.#document.body, this.#scriptElement);
  }
}
