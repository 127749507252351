import { DatePipe } from '@angular/common';
import { booleanAttribute, ChangeDetectionStrategy, Component, HostBinding, input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { IconComponent } from '@shared/components/icon/icon.component';
import { TagListComponent } from '@shared/components/tag-list/tag-list.component';
import { articleCardImageHeight } from '@shared/constants/article-card.constans';
import { BtnDirective } from '@shared/directives/button/btn.directive';
import { ArticleCard } from '@shared/types/card.types';
import { localeTransform } from '@shared/utils/localeInputTranformer';

@Component({
  selector: 'vk-article-card',
  standalone: true,
  imports: [DatePipe, RouterModule, BtnDirective, TagListComponent, IconComponent],
  templateUrl: './article-card.component.html',
  styleUrl: './article-card.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArticleCardComponent {
  article = input.required<ArticleCard>();
  buttonText = input<string | undefined>();
  locale = input.required({ transform: localeTransform, alias: 'lang' });
  dateFormat = input<string>('yyyy. MMMM d.');
  listItem = input(false, { transform: booleanAttribute });
  showDetails = input(true, { transform: booleanAttribute });

  @HostBinding('class.article-list-item') get listItemClass(): boolean {
    return this.listItem();
  }

  @HostBinding('style.--article-card-list-item-height.px') get listItemHeight(): number {
    return articleCardImageHeight[this.article().size ?? 'medium'];
  }
}
