<div class="media-with-content-block-container" #content [ngClass]="{ hidden: content.children.length === 0 }">
  <ng-content></ng-content>
</div>
@if (video() || image()) {
  <div class="media-with-content-block-media-container">
    @if (video(); as video) {
      <div class="video-wrapper">
        <vk-video
          [videoUrl]="video.url"
          [clipPathType]="video?.clipPathType ?? 'small'"
          [videoHeight]="video?.cssHeight ?? null"
          [videoWidth]="video?.cssWidth ?? null"
        ></vk-video>
      </div>
    } @else {
      <img
        class="media-with-content-block-media-image"
        [ngClass]="{
          'corner-clip': !!image()?.cornerClip,
          large: image()?.cornerClip === 'large',
          medium: image()?.cornerClip === 'medium',
          small: image()?.cornerClip === 'small'
        }"
        [src]="image()!.url"
        [alt]="image()!.alt"
      />
    }
  </div>
}
