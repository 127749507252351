import { DOCUMENT } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, inject, input, OnDestroy, Renderer2 } from '@angular/core';

@Component({
  selector: 'vk-facebook-video-embed',
  standalone: true,
  imports: [],
  templateUrl: './facebook-video-embed.component.html',
  styleUrl: './facebook-video-embed.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FacebookVideoEmbedComponent implements AfterViewInit, OnDestroy {
  facebookVideoURL = input<string>('https://fb.watch/tOUVh6uZgB/');
  readonly #renderer = inject(Renderer2);
  readonly #document = inject(DOCUMENT);

  #scriptElement: HTMLScriptElement;

  ngAfterViewInit(): void {
    this.#scriptElement = this.#renderer.createElement('script');
    this.#scriptElement.src = 'https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v3.2';
    this.#scriptElement.async = true;
    this.#scriptElement.defer = true;
    this.#renderer.appendChild(this.#document.body, this.#scriptElement);
  }

  ngOnDestroy(): void {
    this.#renderer.removeChild(this.#document.body, this.#scriptElement);
  }
}
