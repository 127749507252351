<p class="password-strength-label">{{ 'PASSWORD_VALIDATION.LABEL' | transloco }}</p>
<ul class="password-strength-list">
  <li class="password-strength-validator" [ngClass]="{ error: inputErrors()?.['minLength'] }">
    @if (inputErrors()?.['minLength']) {
      <vk-icon class="password-strength-bullet" name="icon-bullet" />
    } @else {
      <vk-icon class="password-strength-check" name="icon-check" />
    }
    {{ 'PASSWORD_VALIDATION.MINLENGTH' | transloco }}
  </li>
  <li class="password-strength-validator" [ngClass]="{ error: inputErrors()?.['uppercase'] }">
    @if (inputErrors()?.['uppercase']) {
      <vk-icon class="password-strength-bullet" name="icon-bullet" />
    } @else {
      <vk-icon class="password-strength-check" name="icon-check" />
    }
    {{ 'PASSWORD_VALIDATION.UPPERCASE' | transloco }}
  </li>
  <li class="password-strength-validator" [ngClass]="{ error: inputErrors()?.['lowercase'] }">
    @if (inputErrors()?.['lowercase']) {
      <vk-icon class="password-strength-bullet" name="icon-bullet" />
    } @else {
      <vk-icon class="password-strength-check" name="icon-check" />
    }
    {{ 'PASSWORD_VALIDATION.LOWERCASE' | transloco }}
  </li>
  <li class="password-strength-validator" [ngClass]="{ error: inputErrors()?.['number'] }">
    @if (inputErrors()?.['number']) {
      <vk-icon class="password-strength-bullet" name="icon-bullet" />
    } @else {
      <vk-icon class="password-strength-check" name="icon-check" />
    }
    {{ 'PASSWORD_VALIDATION.NUMBER' | transloco }}
  </li>
  <li class="password-strength-validator" [ngClass]="{ error: inputErrors()?.['specialChar'] }">
    @if (inputErrors()?.['specialChar']) {
      <vk-icon class="password-strength-bullet" name="icon-bullet" />
    } @else {
      <vk-icon class="password-strength-check" name="icon-check" />
    }
    {{ 'PASSWORD_VALIDATION.SPECIAL' | transloco }}
  </li>
</ul>
