import { booleanAttribute, ChangeDetectionStrategy, Component, computed, HostBinding, inject, input } from '@angular/core';
import { LocalizeState } from '@core/states/localize/localize.state';
import { NewsArticleState } from '@features/news/components/news-article/states/news-article.state';
import { FULL_WIDTH } from '@shared/constants';

import { ArticleCardCarouselComponent } from '../article-card-carousel.component';

@Component({
  selector: 'vk-recommended-article-card-carousel-wrapper',
  standalone: true,
  imports: [ArticleCardCarouselComponent],
  templateUrl: './recommended-article-card-carousel-wrapper.component.html',
  styleUrl: './recommended-article-card-carousel-wrapper.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RecommendedArticleCardCarouselWrapperComponent {
  @HostBinding(FULL_WIDTH) readonly fullWidth = true;

  title = input.required<string>();
  description = input<string | undefined>();
  buttonText = input<string | undefined>();
  withTags = input(false, { transform: booleanAttribute });
  listItem = input(false, { transform: booleanAttribute });
  showDetails = input(false, { transform: booleanAttribute });
  goldBackground = input(false, { transform: booleanAttribute });

  readonly localizeState = inject(LocalizeState);
  readonly newsArticleState = inject(NewsArticleState);

  readonly articles = computed(() =>
    this.newsArticleState.recommendedArticles().map((article) => ({ ...article, tags: this.withTags() ? article.tags : [] }))
  );

  constructor() {
    this.newsArticleState.fetchRecommendedArticles();
  }
}
