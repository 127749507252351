<div class="map-block-container">
  <h2 class="map-block-title is-alternative block-title">{{ title() }}</h2>
  @if (description()) {
    <p class="map-block-description">{{ description() }}</p>
  }
  <a class="map-block-btn" [routerLink]="button().url" scheme="white" vkBtn><vk-icon name="icon-signpost-big" /> {{ button().title }}</a>
</div>

<div class="map-block-media-container">
  <img class="map-block-media-container-image" [src]="image()!.url" [alt]="image()!.alt" />
</div>
