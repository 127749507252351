import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';
import { RouterLink } from '@angular/router';
import { DefaultBlockComponent } from '@shared/components/default-block/default-block.component';
import { IconComponent } from '@shared/components/icon/icon.component';
import { MediaWithContentBlockComponent } from '@shared/components/media-with-content-block/media-with-content-block.component';
import { VideoComponent } from '@shared/components/video/video.component';
import { BtnDirective } from '@shared/directives';
import { BlockButton, BlockImage, BlockVideo, buttonIconWithArrowRight, DefaultContent } from '@shared/types';

@Component({
  selector: 'vk-hero-block',
  standalone: true,
  imports: [RouterLink, MediaWithContentBlockComponent, IconComponent, VideoComponent, BtnDirective, DefaultBlockComponent],
  templateUrl: './hero-block.component.html',
  styleUrl: './hero-block.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeroBlockComponent {
  title = input.required<string>();
  button = input.required({
    transform: (value: BlockButton) => ({
      ...buttonIconWithArrowRight,
      ...value,
    }),
  });
  descriptionBlocks = input<ReadonlyArray<DefaultContent>>([]);
  image = input.required({
    transform: (value: BlockImage | undefined) =>
      value
        ? ({
            maxHeight: 420,
            maxWidth: 644,
            cornerClip: true,
            ...value,
          } as BlockImage)
        : undefined,
  });
  videoUrl = input.required<string | undefined>();

  readonly video = computed<BlockVideo | undefined>(() => {
    return this.videoUrl()
      ? ({
          url: this.videoUrl(),
          cssHeight: '365px',
          cssWidth: '644px',
        } as BlockVideo)
      : undefined;
  });
}
