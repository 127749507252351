import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, input } from '@angular/core';
import { JotFormEmbedComponent } from '@shared/components/embed/jot-form-embed/jot-form-embed.component';
import { BlockMediaPlace } from '@shared/types';

@Component({
  selector: 'vk-jot-form-with-content-block',
  standalone: true,
  imports: [CommonModule, JotFormEmbedComponent],
  templateUrl: './jot-form-with-content-block.component.html',
  styleUrl: './jot-form-with-content-block.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JotFormWithContentBlockComponent {
  jotId = input.required<string>();
  formHeight = input.required({ transform: (value: number | undefined): number => (value ? (value as number) : this.#defaultFormHeight) });
  formPlace = input<BlockMediaPlace>('right');

  readonly #defaultFormHeight = 1300;

  @HostBinding('style.--jot-form-with-content-block-flex-direction') get flexDirectionStyle():
    | 'row'
    | 'row-reverse'
    | 'column'
    | 'column-reverse' {
    switch (this.formPlace()) {
      case 'right':
        return 'row';
      case 'left':
        return 'row-reverse';
      case 'top':
        return 'column-reverse';
      case 'bottom':
        return 'column';
    }
  }
}
