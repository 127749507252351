import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  contentChild,
  effect,
  ElementRef,
  HostBinding,
  HostListener,
  inject,
  input,
  TemplateRef,
  viewChildren,
} from '@angular/core';
import { MasonryItemDirective } from '@shared/components/masonry-list/directives/masonry-item.directive';

@Component({
  selector: 'vk-masonry-list',
  standalone: true,
  imports: [CommonModule, MasonryItemDirective],
  templateUrl: './masonry-list.component.html',
  styleUrl: './masonry-list.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MasonryListComponent<TData extends object> {
  items = input.required<readonly TData[]>();

  elements = viewChildren<ElementRef<HTMLLIElement>>('listItem');
  itemContentChildTemplate = contentChild(MasonryItemDirective, { read: TemplateRef });

  readonly #cdr = inject(ChangeDetectorRef);

  @HostBinding('style.--masonry-list-row-gap.px') readonly realRowGap = 16;

  constructor() {
    effect(() => {
      if (this.items()) {
        this.#cdr.detectChanges();
      }
    });
  }

  // Forse change detection to ensure individual item size is always correct
  @HostListener('window:resize', ['$event.target.innerWidth']) onResize(): void {
    this.#cdr.markForCheck();
  }

  // main magic function that calculates the span of the list items
  getRowSpan(height: number): number {
    const expectedRowGap = 32;
    const sweetspotMagicNumber = 12;
    const realRowGap = this.realRowGap;
    const extraSpan = height % expectedRowGap > sweetspotMagicNumber ? 2 : 1;

    return Math.ceil(height / realRowGap) + extraSpan;
  }
}
