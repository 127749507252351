<img class="subbrand-card-logo" [src]="logo()" [alt]="title()" />

<div class="subbrand-card-body">
  <div class="subbrand-card-info">
    @if (title()) {
      <h3 class="subbrand-card-title">{{ title() }}</h3>
    }
    @if (description()) {
      <p>{{ description() }}</p>
    }
  </div>
  <div class="subbrand-card-btn-container">
    <ng-content></ng-content>
  </div>
</div>
