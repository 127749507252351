import { A11yModule } from '@angular/cdk/a11y';
import { CommonModule } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, input, output } from '@angular/core';
import { IconComponent } from '@shared/components/icon/icon.component';
import { ToasterData } from '@shared/components/toaster/models/toaster.model';

@Component({
  selector: 'vk-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
  standalone: true,
  imports: [CommonModule, IconComponent, A11yModule, IconComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToastComponent implements AfterViewInit {
  data = input.required<ToasterData>();
  ariaCloseLabel = input.required<string>();

  closeToast = output<void>();

  #timer: ReturnType<typeof setTimeout> | undefined = undefined;

  readonly #cdr = inject(ChangeDetectorRef);

  onClickClose(): void {
    if (this.#timer) {
      clearTimeout(this.#timer);
    }

    this.closeToast.emit();
    this.#cdr.markForCheck();
  }

  ngAfterViewInit(): void {
    if (this.data().delay) {
      this.#timer = setTimeout(() => {
        this.onClickClose();
      }, this.data().delay);
    }

    this.#cdr.markForCheck();
  }
}
