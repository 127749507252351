@if (errors(); as errors) {
  @if (errors?.['required']) {
    <p class="error">{{ 'ERRORS.REQUIRED' | transloco }}</p>
  }

  @if (errors?.['pattern']) {
    <p class="error">{{ 'ERRORS.PATTERN' | transloco }}</p>
  }

  @if (errors?.['email']) {
    <p class="error">{{ 'ERRORS.EMAIL' | transloco }}</p>
  }

  @if (errors?.['requiredTrue']) {
    <p class="error">{{ 'ERRORS.REQUIREDTRUE' | transloco }}</p>
  }
}
