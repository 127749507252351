import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, input } from '@angular/core';
import { TranslocoPipe } from '@jsverse/transloco';
import { ToastComponent } from '@shared/components/toaster/components/toast/toast.component';
import { ToasterData } from '@shared/components/toaster/models/toaster.model';
import { ToasterService } from '@shared/components/toaster/services/toaster.service';

@Component({
  selector: 'vk-toaster-container',
  standalone: true,
  imports: [CommonModule, TranslocoPipe, ToastComponent],
  templateUrl: './toaster-container.component.html',
  styleUrls: ['./toaster-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToasterContainerComponent {
  data = input<readonly ToasterData[]>([]);

  readonly #tosterService = inject(ToasterService);

  onCloseToast(index: number): void {
    this.#tosterService.closeToaster(index);
  }
}
