import { booleanAttribute, ChangeDetectionStrategy, Component, HostBinding, input } from '@angular/core';
import { IconComponent } from '@shared/components/icon/icon.component';
import { InfoBackgrondStyles } from '@shared/types';

@Component({
  selector: 'vk-info',
  standalone: true,
  imports: [IconComponent],
  templateUrl: './info.component.html',
  styleUrl: './info.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfoComponent {
  info = input.required<string>();
  withPadding = input(false, { transform: booleanAttribute });
  backgroundColor = input<InfoBackgrondStyles>('blue-light');

  @HostBinding('class.with-padding') get withPaddingClass(): boolean {
    return this.withPadding();
  }

  @HostBinding('style.--info-backround-color') get backgroundColorVar(): string {
    return `var(--info-${this.backgroundColor()}-backround-color)`;
  }
}
