import { ChangeDetectionStrategy, Component, HostBinding } from '@angular/core';
import { TranslocoPipe } from '@jsverse/transloco';
import { FULL_WIDTH } from '@shared/constants';

import { NewsletterBlockComponent } from '../newsletter-block.component';

@Component({
  selector: 'vk-newsletter-full-width-block-wrapper',
  templateUrl: './newsletter-full-width-block-wrapper.component.html',
  styleUrls: ['./newsletter-full-width-block-wrapper.component.scss'],
  standalone: true,
  imports: [TranslocoPipe, NewsletterBlockComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewsletterFullWidthBlockWrapperComponent {
  @HostBinding(FULL_WIDTH) readonly fullWidth = true;
}
