import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { FeaturedArticleCardComponent } from '@shared/components/featured-article-card/featured-article-card.component';
import { IconComponent } from '@shared/components/icon/icon.component';
import { BtnDirective } from '@shared/directives/button/btn.directive';
import { ArticleCard } from '@shared/types/card.types';
import { AvailableLangs } from 'src/environments';

@Component({
  selector: 'vk-featured-article-list',
  standalone: true,
  imports: [FeaturedArticleCardComponent, BtnDirective, IconComponent],
  templateUrl: './featured-article-list.component.html',
  styleUrl: './featured-article-list.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FeaturedArticleListComponent {
  articles = input<readonly ArticleCard[]>([]);
  lang = input.required<AvailableLangs>();
}
