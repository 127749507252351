<div class="sight-brand-block">
  <figure class="sight-brand-block-background">
    <img class="sight-brand-block-image" [alt]="image().alt" [src]="image().url" />
  </figure>
  <div class="sight-brand-block-wrapper">
    <div
      class="sight-brand-block-logo"
      [ngStyle]="{
        'mask-image': logoUrl(),
        'mask-repeat': 'no-repeat',
        'background-color': 'white'
      }"
    ></div>
    <h3 class="sight-brand-block-title">{{ title() }}</h3>
    <p class="sight-brand-block-lead">
      {{ description() }}
    </p>
    <a class="sight-brand-block-link" [href]="button().url" [scheme]="'dark'" vkBtn
      >{{ button().title }} <vk-icon [name]="'icon-external'"
    /></a>
  </div>
</div>
