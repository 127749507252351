<label class="checkbox-wrapper" [formGroup]="form" [ngClass]="{ 'is-disabled': formControl.disabled }">
  <input class="checkbox" [id]="controlName()" [formControlName]="controlName()" type="checkbox" />
  <p class="checkbox-text">
    <ng-content></ng-content>
  </p>
</label>
<div>
  @if (formControl.invalid && formControl.touched && formControl.errors) {
    <vk-client-validation-messages [errors]="formControl.errors"></vk-client-validation-messages>
  }
</div>
