<h3 class="article-list-title block-title breakout">Ezek a cikkek is érdekelhetnek</h3>
@if (articles(); as articles) {
  @if (!carousel()) {
    <ul class="article-list breakout">
      @for (article of articles; track $index) {
        <li>
          <vk-article-card [article]="article" [buttonText]="buttonText()" [lang]="lang()" />
        </li>
      }
    </ul>
  } @else {
    <vk-carousel class="article-carousel" [data]="articles" hasFirstItemOffset hasLastItemOffset>
      <ng-template [vkCarouselItem]="articles" let-article>
        <vk-article-card [article]="article" [buttonText]="buttonText()" [lang]="lang()" />
      </ng-template>
    </vk-carousel>
  }
}
