<div class="ticket-card">
  @if (ticketData(); as ticketData) {
    <header>
      <h3 class="ticket-card-title is-alternative">
        <a class="ticket-card-title-slug" [routerLink]="ticketData.slug">{{ ticketData.title }}</a>
      </h3>
      <p class="ticket-card-description">{{ ticketData.description }}</p>
    </header>

    <button class="ticket-card-pdf-download" [disabled]="!ticketData.pdfUrl" (click)="openPdfUrl(ticketData.pdfUrl)" vkBtn>
      PDF
      <vk-icon name="icon-download-cloud" />
    </button>

    <span class="ticket-card-seperator" aria-hidden="true"></span>
    <div class="ticket-card-date">
      {{ dateTitle() }}
      <time class="ticket-card-date-time" [attr.datetime]="ticketData.date">{{
        ticketData.date | date: dateFormat() : '' : locale()
      }}</time>
    </div>
    <div class="ticket-card-full-price">
      {{ priceTitle() }}
      <data class="ticket-card-full-price-money" [attr.value]="ticketData.price">{{
        ticketData.price | currency: 'Ft' : 'symbol' : '1.0'
      }}</data>
    </div>
  }
</div>
