<div class="two-calendar-date-range-picker-wrapper">
  <mat-calendar
    class="two-calendar-date-range-picker-calendar"
    [(selected)]="selectedDateRange"
    [startAt]="startDate()"
    [dateFilter]="isInactiveFilter"
    [headerComponent]="leftMonthSelectorDatepickerHeaderComponent"
    [minDate]="minDate()"
    (selectedChange)="rangeChanged($event)"
  ></mat-calendar>
  <mat-calendar
    class="two-calendar-date-range-picker-calendar"
    [(selected)]="selectedDateRange"
    [startAt]="endDate()"
    [dateFilter]="isInactiveFilter"
    [headerComponent]="rightMonthSelectorDatepickerHeaderComponent"
    (selectedChange)="rangeChanged($event)"
  ></mat-calendar>
</div>
