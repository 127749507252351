<article class="article-card">
  @if (media()) {
    <img class="article-card-image" [src]="media()?.url" [alt]="title()" />
  }
  <div class="article-card-body">
    <a class="article-card-title-block" [routerLink]="url()">
      <h3 class="is-alternative">{{ title() }}</h3>
      <vk-icon class="article-card-title-icon" name="icon-arrow-right" />
    </a>
    <div class="article-card-meta-block">
      <span class="article-card-date">{{ publishedAt() | date: dateFormat() : '' : locale() }}</span>
      <vk-tag-list class="article-card-tags" [tags]="tags()" card />
    </div>
  </div>
</article>
