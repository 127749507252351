import { ChangeDetectionStrategy, Component, input } from '@angular/core';

@Component({
  selector: 'vk-subbrands',
  standalone: true,
  imports: [],
  templateUrl: './subbrands.component.html',
  styleUrl: './subbrands.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubbrandsComponent {
  subBrands = input.required<{ imageUrl: string; alt: string; url: string }[]>();
  imageWidths: number[] = [];

  setColumnWidth(event: Event, index: number): void {
    const imgElement = event.target as HTMLImageElement;
    this.imageWidths[index] = imgElement.width;
  }
}
