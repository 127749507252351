import { NgClass } from '@angular/common';
import { booleanAttribute, ChangeDetectionStrategy, Component, contentChild, HostBinding, inject, input, TemplateRef } from '@angular/core';
import { BreakpointState } from '@core/states/breakpoint/breakpoint.state';
import { CarouselComponent } from '@shared/components/carousel/carousel.component';
import { CarouselItemDirective } from '@shared/components/carousel/directives/carousel-item.directive';

@Component({
  selector: 'vk-carousel-block',
  standalone: true,
  imports: [CarouselComponent, NgClass, CarouselItemDirective],
  providers: [CarouselBlockComponent],
  templateUrl: './carousel-block.component.html',
  styleUrl: './carousel-block.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CarouselBlockComponent<TData extends Object> {
  data = input.required<readonly TData[]>();
  title = input.required<string>();
  description = input<string | undefined>();
  verticalTitle = input(false, { transform: booleanAttribute });
  goldBackground = input(false, { transform: booleanAttribute });
  fullWidth = input(true);

  breakpointState = inject(BreakpointState);

  itemTemplate = contentChild(CarouselItemDirective, { read: TemplateRef });

  @HostBinding('class.gold-bg') get goldBgClass(): boolean {
    return this.goldBackground();
  }

  @HostBinding('class.full-width') get fullWidthClass(): boolean {
    return this.fullWidth();
  }
}
