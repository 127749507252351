export type DefaultContent =
  | DefaultParagraph
  | DefaultHeader
  | DefaultLink
  | DefaultList
  | DefaultChecklist
  | DefaultQuote
  | DefaultImage
  | DefaultTable
  | DefaultDelimiter;

export enum DefaultContentType {
  Paragraph = 'paragraph',
  Header = 'header',
  Link = 'link',
  List = 'list',
  Checklist = 'checklist',
  Quote = 'quote',
  Image = 'image',
  Table = 'table',
  Delimiter = 'delimiter',
}

export type AcceptedDefaultContentTypeList = DefaultContentType[] | 'all';

export type DefaultParagraph = {
  readonly type: DefaultContentType.Paragraph;
  readonly data: {
    readonly text: string;
  };
};

export type DefaultHeader = {
  readonly type: DefaultContentType.Header;
  readonly data: {
    readonly text: string;
    readonly level: 1 | 2 | 3 | 4 | 5;
  };
};

export type DefaultDelimiter = {
  readonly type: DefaultContentType.Delimiter;
};

export type DefaultLink = {
  readonly type: DefaultContentType.Link;
  readonly data: {
    readonly link: string;
    readonly meta: string[];
  };
};

export type DefaultList = {
  readonly type: DefaultContentType.List;
  readonly data: {
    readonly style: 'ordered' | 'unordered';
    readonly items: string[];
  };
};

export type DefaultChecklist = {
  readonly type: DefaultContentType.Checklist;
  readonly data: {
    readonly items: { readonly text: string; readonly checked: boolean }[];
  };
};

export type DefaultQuote = {
  readonly type: DefaultContentType.Quote;
  readonly data: { readonly text: string; readonly caption: string; readonly alignment: 'left' | 'right' };
};

export type DefaultImage = {
  readonly type: DefaultContentType.Image;
  readonly data: {
    readonly file: {
      readonly url: string;
      readonly fileId: string;
    };
    readonly caption: string;
    readonly withBorder: boolean;
    readonly stretched: boolean;
    readonly withBackground: boolean;
  };
};

export type DefaultTable = {
  readonly type: DefaultContentType.Table;
  readonly data: { readonly withHeadings: boolean; readonly content: string[][] };
};
