import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, input, signal } from '@angular/core';
import { CarouselComponent } from '@shared/components/carousel/carousel.component';
import { CarouselItemDirective } from '@shared/components/carousel/directives/carousel-item.directive';
import { DialogComponent } from '@shared/components/dialog/dialog.component';
import { BtnDirective } from '@shared/directives';
import { BlockButton, GalleryData } from '@shared/types';

@Component({
  selector: 'vk-dialog-gallery-with-button-block',
  standalone: true,
  imports: [CommonModule, DialogComponent, CarouselComponent, CarouselItemDirective, BtnDirective],
  templateUrl: './dialog-gallery-with-button-block.component.html',
  styleUrl: './dialog-gallery-with-button-block.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogGalleryBlockWithButtonComponent {
  gallery = input.required<GalleryData>();
  button = input.required<BlockButton>();

  readonly dialogOpened = signal<boolean>(false);

  onDialogOpen($event: MouseEvent): void {
    $event.preventDefault();
    this.dialogOpened.set(true);
  }
}
