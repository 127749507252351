import { AvailableLangs, AvailableLocales } from 'src/environments';

export const localeTransform = (lang: AvailableLangs | undefined): AvailableLocales => {
  switch (lang) {
    case 'hu':
      return 'hu-HU';
    case 'en':
      return 'en-GB';
    default:
      return 'hu-HU';
  }
};
