import { inject, Injectable, Signal } from '@angular/core';
import { ProgramsService } from '@core/services/programs/programs.service';
import { tapResponse } from '@ngrx/operators';
import { patchState, signalState } from '@ngrx/signals';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { ProgramCard } from '@shared/types';
import { AbstractApiCallState } from '@shared/utils';
import { debounceTime, exhaustMap, pipe, tap } from 'rxjs';
import { ENVIRONMENT } from 'src/environments';

export type ProgramsStateType = {
  readonly programRecommendations: ReadonlyArray<ProgramCard>;
};

const initialProgramsState: ProgramsStateType = {
  programRecommendations: [],
};

@Injectable({
  providedIn: 'root',
})
export class ProgramsState extends AbstractApiCallState {
  readonly #programsState = signalState<ProgramsStateType>(initialProgramsState);
  readonly #programsService = inject(ProgramsService);
  readonly #environment = inject(ENVIRONMENT);

  readonly programRecommendations: Signal<ReadonlyArray<ProgramCard>> = this.#programsState.programRecommendations;

  readonly fetchProgramRecommendations = rxMethod<void>(
    pipe(
      tap(() => this.setLoading()),
      debounceTime(this.#environment.delay),
      exhaustMap(() => {
        return this.#programsService.getProgramRecommendations$().pipe(
          tapResponse({
            next: (programs) => {
              patchState(this.#programsState, { programRecommendations: programs });
            },
            error: this.setError,
            finalize: () => this.setLoaded(),
          })
        );
      })
    )
  );
}
