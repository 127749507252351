import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { TranslocoPipe } from '@jsverse/transloco';

@Component({
  selector: 'vk-client-validation-messages',
  standalone: true,
  imports: [TranslocoPipe],
  templateUrl: './client-validation-messages.component.html',
  styleUrl: './client-validation-messages.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClientValidationMessagesComponent {
  errors = input<ValidationErrors | null>(null);
}
