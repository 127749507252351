import { CommonModule } from '@angular/common';
import { booleanAttribute, ChangeDetectionStrategy, Component, computed, input } from '@angular/core';

export type SpinnerSizeType = 'button' | 'page';
@Component({
  selector: 'vk-spinner',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpinnerComponent {
  pageSize = input(false, { transform: booleanAttribute });
  readonly size = computed<number>(() => (this.pageSize() ? 80 : 24));
}
