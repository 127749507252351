<video
  class="video-player"
  #player
  [@fade]="fadeState"
  [src]="videoUrl()"
  [ngClass]="{
    'small-clip-path': clipPathType() === 'small',
    'no-clip-path': clipPathType() === 'none',
    animation: !!animation(),
    hidden: animation() === 'hidden',
    visible: animation() === 'visible'
  }"
  (timeupdate)="updateProgressBar()"
  (ended)="onVideoEnd()"
  muted
  preload="auto"
  loading="lazy"
>
  Your browser does not support the video tag.
</video>
<div class="video-player-controls">
  <button class="video-player-controls-button" #btnPlayPause (click)="playPauseVideo()">
    <vk-icon [size]="32" [name]="'icon-' + iconName()" />
  </button>
  <progress class="video-player-controls-progress" [value]="percentage()" min="0" max="100">{{ percentage() + '% played' }}</progress>
</div>
