import { ChangeDetectionStrategy, Component, HostBinding, input } from '@angular/core';
import { ArticleCardComponent } from '@shared/components/article-card/article-card.component';
import { CarouselComponent } from '@shared/components/carousel/carousel.component';
import { CarouselItemDirective } from '@shared/components/carousel/directives/carousel-item.directive';
import { FULL_WIDTH } from '@shared/constants';
import { ArticleCard } from '@shared/types/card.types';
import { AvailableLangs } from 'src/environments';

@Component({
  selector: 'vk-article-card-list',
  standalone: true,
  imports: [ArticleCardComponent, CarouselComponent, CarouselItemDirective],
  templateUrl: './article-card-list.component.html',
  styleUrl: './article-card-list.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArticleCardListComponent {
  @HostBinding(FULL_WIDTH) readonly fullWidth = true;

  articles = input<readonly ArticleCard[]>([]);
  lang = input.required<AvailableLangs>();
  buttonText = input.required<string>();
  carousel = input<boolean>(false);
}
