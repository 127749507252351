<section class="carousel-block" [ngClass]="{ vertical: verticalTitle() }">
  <aside class="carousel-block-aside" [ngClass]="{ vertical: verticalTitle() }">
    <h2 class="carousel-block-aside-title">{{ title() }}</h2>
    @if (!verticalTitle()) {
      @if (description()) {
        <p class="carousel-block-aside-description">{{ description() }}</p>
      }
      <ng-content select="[vkBtn]"></ng-content>
    }
  </aside>

  <vk-carousel
    class="carousel-block-carousel"
    [data]="data()"
    [itemTemplate]="itemTemplate()"
    [hasFirstItemOffset]="breakpointState.isMobile()"
    hasLastItemOffset
  />
</section>
