<iframe
  width="315"
  height="560"
  src="https://www.youtube.com/embed/DLKTvU8hspc"
  title="YouTube video player"
  frameborder="0"
  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
  allowfullscreen
  style="border-radius: 12px"
></iframe>
