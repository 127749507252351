<div class="sight-brand-block">
  <figure class="sight-brand-block-background">
    <img class="sight-brand-block-image" [alt]="image().alt" [src]="image().url" />
  </figure>
  <div class="sight-brand-block-wrapper">
    <img class="sight-brand-block-logo" [src]="logoUrl()" alt="" />
    <h2 class="sight-brand-block-title">{{ title() }}</h2>
    <div class="sight-brand-block-description-wrapper">
      <vk-default-block class="sight-brand-block-description small-text" [blocks]="descriptionBlocks()" />
    </div>
    <a class="sight-brand-block-link" [href]="button().url" [scheme]="buttonStyle().sheme" [vkBtn]="buttonStyle().theme"
      >{{ button().title }} <vk-icon [name]="'icon-external'"
    /></a>
  </div>
</div>
