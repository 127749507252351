import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  ElementRef,
  HostListener,
  inject,
  input,
  OnInit,
  output,
  signal,
} from '@angular/core';
import {
  ControlContainer,
  FormControl,
  FormGroup,
  NonNullableFormBuilder,
  ReactiveFormsModule,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { LabelComponent } from '@shared/components/forms/components/label/label.component';
import { PasswordStrengthValidatorComponent } from '@shared/components/forms/components/password-strength-validator/password-strength-validator.component';
import { ClientValidationMessagesComponent } from '@shared/components/forms/errors/client-validation-messages/client-validation-messages.component';
import { IconComponent } from '@shared/components/icon/icon.component';
import { AutoFocusDirective } from '@shared/directives';
import { passwordStrengthValidator } from '@shared/utils';

@Component({
  selector: 'vk-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    PasswordStrengthValidatorComponent,
    ClientValidationMessagesComponent,
    LabelComponent,
    IconComponent,
    AutoFocusDirective,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputComponent implements OnInit {
  controlName = input.required<string>();
  label = input.required<string>();
  type = input<'text' | 'email' | 'password'>('text');
  value = input<string>('');
  autoFocus = input<boolean>(false);
  required = input<boolean>(false);
  checkPasswordStrength = input<boolean>(false);
  minLength = input<number | undefined>();
  placeholder = input<string>('');
  description = input<string | undefined>();
  iconName = input<string | undefined>();

  buttonClick = output<string>();

  readonly #elementRef = inject(ElementRef);
  readonly #fb = inject(NonNullableFormBuilder);
  readonly #parentContainer = inject(ControlContainer);

  readonly focused = signal<boolean>(false);
  readonly blurred = signal<boolean>(false);
  readonly validators = computed(() => {
    const validators: ValidatorFn[] = [];
    this.required() ? validators.push(Validators.required) : null;
    this.type() === 'email' ? validators.push(Validators.email) : null;
    this.type() === 'password' && this.checkPasswordStrength() ? validators.push(passwordStrengthValidator()) : null;
    typeof this.minLength() === 'number' && (this.minLength() as number) > 0
      ? validators.push(Validators.minLength(this.minLength() as number))
      : null;

    return validators;
  });

  get form(): FormGroup {
    return this.#parentContainer.control as FormGroup;
  }

  get formControl(): FormControl<string> {
    return this.form.get(this.controlName()) as FormControl<string>;
  }

  ngOnInit(): void {
    if (!this.form.contains(this.controlName())) {
      this.form.addControl(this.controlName(), this.#fb.control(this.value(), this.validators()));
    }
  }

  onFocus(): void {
    this.focused.set(true);
    this.blurred.set(false);
  }

  onBlur(): void {
    this.focused.set(false);
    if (typeof this.iconName() === 'string' && !this.blurred()) {
      this.formControl.markAsUntouched();
    }
  }

  emitClick(): void {
    this.buttonClick.emit(this.form.controls[this.controlName()].value);
  }

  @HostListener('document:mousedown', ['$event'])
  onGlobalClick(event: Event): void {
    event.stopImmediatePropagation();
    if (!this.#elementRef.nativeElement.contains(event.target) && this.focused() && !this.formControl.touched) {
      this.blurred.set(true);
    }
  }
}
