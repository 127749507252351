import { Directive, Input } from '@angular/core';

interface MasonryItemContext<TData> {
  readonly $implicit: TData;
}

@Directive({
  selector: 'ng-template[vkMasonryItem]',
  standalone: true,
})
export class MasonryItemDirective<TData> {
  static ngTemplateContextGuard<TContextData>(
    _dir: MasonryItemDirective<TContextData>,
    _ctx: unknown
  ): _ctx is MasonryItemContext<TContextData> {
    return true;
  }
  @Input({ alias: 'vkMasonryItem', required: true }) data: readonly TData[];
}
