@if (articles(); as articles) {
  <ul class="news-block-main-list">
    @if (articles[0]; as article) {
      <li>
        <vk-featured-article-card
          [title]="article.title"
          [publishedAt]="article.publishedAt"
          [media]="article.media"
          [slug]="article.slug"
          [url]="article.url"
          [tags]="article.tags"
          [lang]="lang()"
        />
      </li>
    }
  </ul>
  <ul class="news-block-secondary-list">
    @for (article of articles; track $index; let first = $first) {
      @if (first) {
        <hr class="news-list-item-divider first-divider" />
      } @else {
        <li>
          <vk-featured-article-card
            [title]="article.title"
            [publishedAt]="article.publishedAt"
            [slug]="article.slug"
            [url]="article.url"
            [tags]="article.tags"
            [lang]="lang()"
          />
        </li>

        <hr class="news-list-item-divider" />
      }
    }
    <li>
      <ng-content select="[vkBtn]" />
    </li>
  </ul>
}
