import { ChangeDetectionStrategy, Component, HostBinding, input } from '@angular/core';
import { AcceptedDefaultContentTypeList, DefaultContent } from '@shared/types';

@Component({
  selector: 'vk-default-block',
  standalone: true,
  imports: [],
  templateUrl: './default-block.component.html',
  styleUrl: './default-block.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DefaultBlockComponent {
  blocks = input<readonly DefaultContent[]>([]);
  acceptedBlockTypes = input<AcceptedDefaultContentTypeList>('all');
  contentWidth = input<number>(870);
  blockGap = input<number>(16);
  textGap = input<number>(8);

  @HostBinding('style.--default-block-max-width') get contentWidthVar(): string {
    return `${this.contentWidth()}px`;
  }

  @HostBinding('style.--default-block-gap') get blockGapVar(): string {
    return `${this.blockGap()}px`;
  }

  @HostBinding('style.--default-block-text-gap') get textGapVar(): string {
    return `${this.textGap()}px`;
  }
}
