import { CommonModule, formatDate } from '@angular/common';
import { ChangeDetectionStrategy, Component, effect, inject, input, model, OnInit } from '@angular/core';
import { ControlContainer, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import {
  DateRange,
  DefaultMatCalendarRangeStrategy,
  MatDatepickerModule,
  MatDateRangeInput,
  MatRangeDateSelectionModel,
} from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

import { LefttMonthSelectorDatepickerHeaderComponent } from './headers/left-month-selector-datepicker-header/left-month-selector-datepicker-header.component';
import { RightMonthSelectorDatepickerHeaderComponent } from './headers/right-month-selector-datepicker-header/right-month-selector-datepicker-header.component';
import { TwoCalendarDateRangeInlinePickerState } from './states/two-calendar-date-range-inline-picker.state';

@Component({
  selector: 'vk-two-calendar-date-range-inline-picker',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    RightMonthSelectorDatepickerHeaderComponent,
    LefttMonthSelectorDatepickerHeaderComponent,
  ],
  providers: [DefaultMatCalendarRangeStrategy, MatRangeDateSelectionModel, MatDateRangeInput],
  templateUrl: './two-calendar-date-range-inline-picker.component.html',
  styleUrl: './two-calendar-date-range-inline-picker.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TwoCalendarDateRangeInlinePickerComponent implements OnInit {
  readonly #parentContainer = inject(ControlContainer);
  readonly #selectionModel = inject(MatRangeDateSelectionModel<Date>);
  readonly #selectionStrategy = inject(DefaultMatCalendarRangeStrategy<Date>);
  readonly #twoCalendarDateRangeInlinePickerState = inject(TwoCalendarDateRangeInlinePickerState<Date>);

  readonly rightMonthSelectorDatepickerHeaderComponent = RightMonthSelectorDatepickerHeaderComponent;
  readonly leftMonthSelectorDatepickerHeaderComponent = LefttMonthSelectorDatepickerHeaderComponent;

  readonly startDate = model.required<Date>();
  readonly endDate = model.required<Date>();
  readonly selectedDateRange = model(new DateRange<Date>(null, null));
  readonly inactiveDates = input<Date[]>([]);
  readonly minDate = input<Date>(new Date());

  readonly isInactiveFilter = (d: Date | null): boolean => {
    if (!d) {
      return false;
    }

    return !this.isDateInInactives(d);
  };

  constructor() {
    effect(
      () => {
        this.startDate.set(this.#twoCalendarDateRangeInlinePickerState.leftCurrentDate() as Date);
        this.endDate.set(this.#twoCalendarDateRangeInlinePickerState.rightCurrentDate() as Date);
      },
      { allowSignalWrites: true }
    );
  }

  get form(): FormGroup {
    return this.#parentContainer.control as FormGroup;
  }

  ngOnInit(): void {
    this.endDate().setMonth(this.startDate().getMonth() + 1);
  }

  rangeChanged(selectedDate: Date | null): void {
    const selection = this.#selectionModel.selection,
      newSelection = this.#selectionStrategy.selectionFinished(selectedDate, selection);

    this.#selectionModel.updateSelection(newSelection, this);
    this.selectedDateRange.set(new DateRange<Date>(newSelection.start, newSelection.end));

    this.form.controls['startDate'].setValue(newSelection.start);
    this.form.controls['endDate'].setValue(newSelection.end);
  }

  formatDate(date: Date): string {
    return formatDate(date, 'yyyy-MM-dd', 'hu_HU');
  }

  isDateInInactives(date: Date): boolean {
    return this.inactiveDates().filter((inactiveDate) => this.formatDate(inactiveDate) === this.formatDate(date)).length > 0;
  }
}
