import { Directive, Input } from '@angular/core';

interface CarouselItemContext<TData> {
  readonly $implicit: TData;
  readonly index: number;
}

@Directive({
  selector: 'ng-template[vkCarouselItem]',
  standalone: true,
})
export class CarouselItemDirective<TData> {
  static ngTemplateContextGuard<TContextData>(
    _dir: CarouselItemDirective<TContextData>,
    _ctx: unknown
  ): _ctx is CarouselItemContext<TContextData> {
    return true;
  }
  @Input({ alias: 'vkCarouselItem', required: true }) data: readonly TData[];
}
