import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, input } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { IconComponent } from '@shared/components/icon/icon.component';

@Component({
  selector: 'vk-navigation-card',
  standalone: true,
  imports: [CommonModule, RouterLink, IconComponent],
  templateUrl: './navigation-card.component.html',
  styleUrl: './navigation-card.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationCardComponent {
  readonly #router = inject(Router);

  title = input.required<string>();
  url = input.required<string>();
  description = input<string>('');
  beforeNavigateFn = input<() => void>();
  iconUrl = input<string | undefined>();

  onNavigate($event: MouseEvent): void {
    $event.stopPropagation();
    if (typeof this.beforeNavigateFn() === 'function') {
      (this.beforeNavigateFn() as () => void)();
    }

    this.#router.navigate([this.url()]);
  }
}
