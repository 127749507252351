export type SeachKey = 'programs' | 'news' | 'sights' | 'faq';

export type Search = { readonly keys: SeachKey[]; readonly value: string };

export enum SearchEnum {
  PROGRAMS = 'programs',
  NEWS = 'news',
  SIGHTS = 'sights',
  FAQ = 'faq',
}

export type SearchKeys = { readonly key: SeachKey; readonly title: string }[];
