<a class="navigation-card-cta" (click)="onNavigate($event)">
  <div class="navigation-card-header">
    <h3 class="navigation-card-title is-alternative" [ngClass]="{ 'no-margin': description().length === 0 }">{{ title() }}</h3>
    <vk-icon class="icon" name="icon-arrow-right"></vk-icon>
  </div>
  @if (description()) {
    <p class="navigation-card-description is-alternative">{{ description() }}</p>
  }
  @if (iconUrl()) {
    <img class="navigation-card-icon" [src]="iconUrl()" [alt]="title()" />
  }
</a>
