import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { TranslocoPipe } from '@jsverse/transloco';
import { ServerError } from '@shared/components/forms/types/form.types';

@Component({
  selector: 'vk-server-validation-messages',
  standalone: true,
  imports: [TranslocoPipe],
  templateUrl: './server-validation-messages.component.html',
  styleUrl: './server-validation-messages.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ServerValidationMessagesComponent {
  serverErrors = input(null, {
    alias: 'error',
    transform: (error: HttpErrorResponse | null): ServerError[] =>
      error?.error?.errors?.map((error: { title: string; message: string }) => ({
        ...error,
        title: `SERVER-ERROR.${error.title.toUpperCase()}`,
      })) || [],
  });
}
