import { contentChild, Directive, effect, ElementRef, inject, OnDestroy, OnInit, Renderer2, signal } from '@angular/core';

type InstanceState = 'idle' | 'singleGesture' | 'multiGesture' | 'mouse';

const DEFAULT_TRANSFORMATION = {
  originOffset: false,
  originX: 0,
  originY: 0,
  translateX: 0,
  translateY: 0,
  scale: 1,
};

const MIN_SCALE = 1;
const MAX_SCALE = 4;
const DOUBLE_TAP_TIME = 185; // milliseconds

@Directive({
  selector: 'div[vkZoomImage]',
  standalone: true,
})
export class ZoomPanClampImagePreviewDirective implements OnInit, OnDestroy {
  readonly #renderer = inject(Renderer2);
  readonly #containerRef: ElementRef<HTMLInputElement> = inject(ElementRef);

  readonly imageRef = contentChild<ElementRef<HTMLImageElement>>('zoomImage');

  readonly #eventListeners = signal<(() => void)[]>([]);
  readonly #scaleValue = signal<number>(1);
  readonly #state = signal<InstanceState>('idle');
  readonly #lastTapTime = signal(0);
  readonly #deviceHasTouch = signal(false);
  readonly #minScale = signal(MIN_SCALE);
  readonly #maxScale = signal(MAX_SCALE);
  readonly #scaleSensitivity = signal<number>(20);
  readonly #transform = signal(DEFAULT_TRANSFORMATION);
  readonly #wheelTimeout = signal<ReturnType<typeof setTimeout> | undefined>(undefined);
  readonly #start = signal<{ x: number; y: number; distance: number; touches: Touch[] }>({ x: 0, y: 0, distance: 0, touches: [] });

  readonly #stateIs = (...states: InstanceState[]): boolean => states.includes(this.#state());

  readonly #getPinchDistance = (event: TouchEvent): number =>
    Math.hypot(event.touches[0].pageX - event.touches[1].pageX, event.touches[0].pageY - event.touches[1].pageY);

  readonly #getMidPoint = (event: TouchEvent): { readonly x: number; readonly y: number } => ({
    x: (event.touches[0].pageX + event.touches[1].pageX) / 2,
    y: (event.touches[0].pageY + event.touches[1].pageY) / 2,
  });

  readonly #onDoubleTap = ({ scale, x, y }: { readonly scale: number; readonly x: number; readonly y: number }): number => {
    if (scale < MAX_SCALE) {
      this.#canZoom().zoomTo({ newScale: MAX_SCALE, x, y });
      return MAX_SCALE;
    } else {
      this.#canInspect().reset();
      return MIN_SCALE;
    }
  };

  readonly #hasPositionChanged = ({ pos, prevPos }: { readonly pos: number; readonly prevPos: number }): boolean => pos !== prevPos;

  readonly #valueInRange = (): boolean => this.#transform().scale <= this.#maxScale() && this.#transform().scale >= this.#minScale();

  readonly #getTranslate =
    () =>
    ({ pos, axis }: { readonly pos: number; readonly axis: 'x' | 'y' }): number => {
      const { originX, originY, translateX, translateY, scale } = this.#transform();
      const axisIsX = axis === 'x';
      const prevPos = axisIsX ? originX : originY;
      const translate = axisIsX ? translateX : translateY;

      return this.#valueInRange() && this.#hasPositionChanged({ pos, prevPos })
        ? translate + (pos - prevPos * scale) * (1 - 1 / scale)
        : translate;
    };

  readonly #getMatrix = ({
    scale,
    translateX,
    translateY,
  }: {
    readonly scale: number;
    readonly translateX: number;
    readonly translateY: number;
  }): string => `matrix(${scale}, 0, 0, ${scale}, ${translateX}, ${translateY})`;

  readonly #clamp = (value: number, min: number, max: number): number => Math.max(Math.min(value, max), min);

  readonly #getNewScale = (deltaScale: number): number => {
    const newScale = this.#transform().scale + deltaScale / (this.#scaleSensitivity() / this.#transform().scale);
    return this.#clamp(newScale, this.#minScale(), this.#maxScale());
  };

  readonly #clampedTranslate = ({ axis, translate }: { readonly axis: 'x' | 'y'; readonly translate: number }): number => {
    const { scale, originX, originY } = this.#transform();
    const axisIsX = axis === 'x';
    const origin = axisIsX ? originX : originY;
    const axisKey = axisIsX ? 'offsetWidth' : 'offsetHeight';

    const containerSize = this.container[axisKey];
    const imageSize = this.image[axisKey];
    const bounds = this.image.getBoundingClientRect();

    const imageScaledSize = axisIsX ? bounds.width : bounds.height;

    const defaultOrigin = imageSize / 2;
    const originOffset = (origin - defaultOrigin) * (scale - 1);

    const range = Math.max(0, Math.round(imageScaledSize) - containerSize);

    const max = Math.round(range / 2);
    const min = 0 - max;

    return this.#clamp(translate, min + originOffset, max + originOffset);
  };

  readonly #renderClamped = ({ translateX, translateY }: { readonly translateX: number; readonly translateY: number }): void => {
    const { originX, originY, scale } = this.#transform();
    this.#transform().translateX = this.#clampedTranslate({ axis: 'x', translate: translateX });
    this.#transform().translateY = this.#clampedTranslate({ axis: 'y', translate: translateY });

    requestAnimationFrame(() => {
      if (this.#transform().originOffset) {
        this.#renderer.setStyle(this.image, 'transformOrigin', `${originX}px ${originY}px`);
      }
      this.#renderer.setStyle(
        this.image,
        'transform',
        this.#getMatrix({
          scale,
          translateX: this.#transform().translateX,
          translateY: this.#transform().translateY,
        })
      );
    });
  };

  readonly #pan = ({ originX, originY }: { readonly originX: number; readonly originY: number }): void => {
    this.#renderClamped({
      translateX: this.#transform().translateX + originX,
      translateY: this.#transform().translateY + originY,
    });
  };

  readonly #canPan = (): {
    readonly panBy: (origin: { readonly originX: number; readonly originY: number }) => void;
    readonly panTo: ({ originX, originY, scale }: { readonly originX: number; readonly originY: number; readonly scale: number }) => void;
  } => ({
    panBy: (origin: { readonly originX: number; readonly originY: number }): void => this.#pan(origin),
    panTo: ({ originX, originY, scale }: { readonly originX: number; readonly originY: number; scale: number }): void => {
      this.#transform().scale = this.#clamp(scale, this.#minScale(), this.#maxScale());

      this.#pan({
        originX: originX - this.#transform().translateX,
        originY: originY - this.#transform().translateY,
      });
    },
  });

  readonly #canZoom = (): {
    readonly zoomPan: ({
      scale,
      x,
      y,
      deltaX,
      deltaY,
    }: {
      readonly scale: number;
      readonly x: number;
      readonly y: number;
      readonly deltaX: number;
      readonly deltaY: number;
    }) => void;
    readonly zoom: ({ x, y, deltaScale }: { readonly x: number; readonly y: number; readonly deltaScale: number }) => void;
    readonly zoomTo: ({ newScale, x, y }: { readonly newScale: number; readonly x: number; readonly y: number }) => void;
  } => ({
    zoomPan: ({
      scale: scaleAlias,
      x,
      y,
      deltaX,
      deltaY,
    }: {
      readonly scale: number;
      readonly x: number;
      readonly y: number;
      readonly deltaX: number;
      readonly deltaY: number;
    }): void => {
      const newScale = this.#clamp(scaleAlias, this.#minScale(), this.#maxScale());
      const { left, top } = this.image.getBoundingClientRect();
      const originX = x - left;
      const originY = y - top;
      const newOriginX = originX / this.#transform().scale;
      const newOriginY = originY / this.#transform().scale;
      const translate = this.#getTranslate();
      const translateX = translate({ pos: originX, axis: 'x' });
      const translateY = translate({ pos: originY, axis: 'y' });

      this.#transform.set({
        originOffset: true,
        originX: newOriginX,
        originY: newOriginY,
        translateX,
        translateY,
        scale: newScale,
      });

      this.#pan({ originX: deltaX, originY: deltaY });
    },
    zoom: ({ x, y, deltaScale }: { readonly x: number; readonly y: number; readonly deltaScale: number }): void => {
      const { left, top } = this.image.getBoundingClientRect();
      const newScale = this.#getNewScale(deltaScale);
      const originX = x - left;
      const originY = y - top;
      const newOriginX = originX / this.#transform().scale;
      const newOriginY = originY / this.#transform().scale;

      const translate = this.#getTranslate();
      const translateX = translate({ pos: originX, axis: 'x' });
      const translateY = translate({ pos: originY, axis: 'y' });

      this.#transform.set({
        ...this.#transform(),
        originOffset: true,
        originX: newOriginX,
        originY: newOriginY,
        scale: newScale,
      });

      this.#renderClamped({ translateX, translateY });
    },
    zoomTo: ({ newScale, x, y }: { readonly newScale: number; readonly x: number; readonly y: number }): void => {
      const { left, top } = this.image.getBoundingClientRect();
      const originX = x - left;
      const originY = y - top;
      const newOriginX = originX / this.#transform().scale;
      const newOriginY = originY / this.#transform().scale;

      const translate = this.#getTranslate();
      const translateX = translate({ pos: originX, axis: 'x' });
      const translateY = translate({ pos: originY, axis: 'y' });

      this.#transform.set({
        originOffset: true,
        originX: newOriginX,
        originY: newOriginY,
        scale: newScale,
        translateX,
        translateY,
      });

      requestAnimationFrame(() => {
        this.#renderer.setStyle(this.image, 'transformOrigin', `${newOriginX}px ${newOriginY}px`);
        this.#renderer.setStyle(
          this.image,
          'transform',
          this.#getMatrix({
            scale: newScale,
            translateX,
            translateY,
          })
        );
      });
    },
  });

  readonly #canInspect = (): { readonly getScale: () => number; readonly reset: () => void } => ({
    getScale: (): number => this.#transform().scale,
    reset: (): void => {
      this.#transform().scale = this.#minScale();
      this.#pan({ originX: 0, originY: 0 });
      this.#transform.set(DEFAULT_TRANSFORMATION);
    },
  });

  /**
   * Event handler for touchstart event
   * @param event TouchEvent
   * @returns void
   */
  readonly #onStartTouch = (event: TouchEvent): void => {
    this.#deviceHasTouch.set(true);

    if (this.#stateIs('multiGesture')) return;

    const touchCount = event.touches.length;

    if (touchCount === 2 && this.#stateIs('idle', 'singleGesture')) {
      const { x, y } = this.#getMidPoint(event);

      this.#start().x = x;
      this.#start().y = y;
      this.#start().distance = this.#getPinchDistance(event) / this.#scaleValue();
      this.#start().touches = [event.touches[0], event.touches[1]];

      this.#lastTapTime.set(0); // Reset to prevent misinterpretation as a double tap
      this.#state.set('multiGesture');
      return;
    }

    if (touchCount !== 1) {
      this.#state.set('idle');
      return;
    }

    this.#state.set('singleGesture');

    const [touch] = Array.from(event.touches);

    this.#start().x = touch.pageX;
    this.#start().y = touch.pageY;
    this.#start().distance = 0;
    this.#start().touches = [touch];
  };

  /**
   * Event handler for touchmove event
   * @param event TouchEvent
   * @returns void
   */
  readonly #onMoveTouch = (event: TouchEvent): void => {
    if (this.#stateIs('idle')) return;

    const touchCount = event.touches.length;

    if (this.#stateIs('multiGesture') && touchCount === 2) {
      event.preventDefault();
      const scale = this.#getPinchDistance(event) / this.#start().distance;

      const { x, y } = this.#getMidPoint(event);

      this.#canZoom().zoomPan({ scale, x, y, deltaX: x - this.#start().x, deltaY: y - this.#start().y });

      this.#start().x = x;
      this.#start().y = y;
      return;
    }

    if (
      this.#scaleValue() === MIN_SCALE ||
      !this.#stateIs('singleGesture') ||
      touchCount !== 1 ||
      event.touches[0]?.identifier !== this.#start().touches[0]?.identifier
    ) {
      return;
    }
    event.preventDefault();

    const [touch] = Array.from(event.touches);

    const deltaX = touch.pageX - this.#start().x;
    const deltaY = touch.pageY - this.#start().y;

    this.#canPan().panBy({ originX: deltaX, originY: deltaY });

    this.#start().x = touch.pageX;
    this.#start().y = touch.pageY;
  };

  /**
   * Event handler for touchend event
   * @param event TouchEvent
   * @returns void
   */
  readonly #onEndTouch = (event: TouchEvent): void => {
    if (this.#stateIs('idle') || event.touches.length !== 0) {
      return;
    }

    const currentTime = new Date().getTime();
    const tapLength = currentTime - this.#lastTapTime();

    if (tapLength < DOUBLE_TAP_TIME && tapLength > 0) {
      event.preventDefault();
      const [touch] = Array.from(event.changedTouches);
      if (!touch) return;
      this.#scaleValue.set(this.#onDoubleTap({ scale: this.#scaleValue(), x: touch.clientX, y: touch.clientY }));
    }

    this.#lastTapTime.set(currentTime);
    this.#scaleValue.set(this.#canInspect().getScale());
    this.#state.set('idle');
  };

  /**
   * Event handler for wheel event
   * @param event WheelEvent
   * @returns void
   */
  readonly #onWheel = (event: WheelEvent): void => {
    if (this.#deviceHasTouch()) return;
    event.preventDefault();
    this.#canZoom().zoom({
      deltaScale: Math.sign(event.deltaY) > 0 ? -1 : 1,
      x: event.pageX,
      y: event.pageY,
    });

    clearTimeout(this.#wheelTimeout());
    this.#wheelTimeout.set(
      setTimeout(() => {
        this.#scaleValue.set(this.#canInspect().getScale());
      }, 100)
    );
  };

  /**
   * Event handler for mousemove event
   * @param event MouseEvent
   * @returns void
   */
  readonly #onMouseMove = (event: MouseEvent): void => {
    if (this.#deviceHasTouch()) return;
    if (this.#scaleValue() === MIN_SCALE) {
      return;
    }

    event.preventDefault();
    if (event.movementX === 0 && event.movementY === 0) {
      return;
    }

    this.#state.set('mouse');
    this.#canPan().panBy({ originX: event.movementX, originY: event.movementY });
  };

  /**
   * Event handler for mouseleave and mouseout event
   * @param event MouseEvent
   * @returns void
   */
  readonly #onMouseEnd = (): void => {
    if (this.#deviceHasTouch()) return;
    this.#state.set('idle');
    this.#scaleValue.set(this.#canInspect().getScale());
  };

  /**
   * Event handler for mouseup event
   * @param event MouseEvent
   * @returns void
   */
  readonly #onMouseUp = (event: MouseEvent): void => {
    if (this.#deviceHasTouch()) return;
    if (!this.#stateIs('mouse')) {
      this.#scaleValue.set(this.#onDoubleTap({ scale: this.#scaleValue(), x: event.pageX, y: event.pageY }));
    }
    this.#onMouseEnd();
  };

  constructor() {
    effect(() => {
      this.#renderer.setStyle(this.container, 'cursor', this.#scaleValue() === MIN_SCALE ? 'zoom-in' : 'move');
    });
  }

  get container(): HTMLDivElement {
    return this.#containerRef.nativeElement;
  }

  get image(): HTMLImageElement {
    return this.imageRef()!.nativeElement;
  }

  ngOnInit(): void {
    if (!this.imageRef()) {
      throw new Error('Image element with #zoomImageRef is required as child of the div[vkZoomImage] directive element.');
    }

    this.#renderer.setAttribute(this.container, 'class', 'img-full-container-centered');

    this.#eventListeners.set([
      this.#renderer.listen(this.container, 'touchstart', (event: TouchEvent) => this.#onStartTouch(event)),
      this.#renderer.listen(this.container, 'touchmove', (event: TouchEvent) => this.#onMoveTouch(event)),
      this.#renderer.listen(this.container, 'touchend', (event: TouchEvent) => this.#onEndTouch(event)),
      this.#renderer.listen(this.container, 'touchcancel', (event: TouchEvent) => this.#onEndTouch(event)),

      this.#renderer.listen(this.container, 'mousedown', () => {
        const unlistenMouseMove = this.#renderer.listen(this.container, 'mousemove', (event: MouseEvent) => this.#onMouseMove(event));
        const unlistenMouseUp = this.#renderer.listen(this.container, 'mouseup', () => {
          unlistenMouseMove();
          unlistenMouseUp();
        });
      }),
      this.#renderer.listen(this.container, 'mouseup', (event: MouseEvent) => this.#onMouseUp(event)),
      this.#renderer.listen(this.container, 'mouseleave', () => this.#onMouseEnd()),
      this.#renderer.listen(this.container, 'mouseout', () => this.#onMouseEnd()),
      this.#renderer.listen(this.container, 'wheel', (event: WheelEvent) => this.#onWheel(event)),
    ]);
  }

  ngOnDestroy(): void {
    this.#eventListeners().forEach((removeListener) => removeListener());
  }
}
