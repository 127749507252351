<vk-article-card-carousel
  class="full-width"
  [articles]="articles()"
  [title]="title()"
  [description]="description()"
  [lang]="localizeState.lang()"
  [showDetails]="showDetails()"
  [listItem]="listItem()"
  [buttonText]="buttonText()"
  [goldBackground]="goldBackground()"
/>
