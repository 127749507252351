import { CommonModule, DOCUMENT } from '@angular/common';
import {
  booleanAttribute,
  ChangeDetectionStrategy,
  Component,
  effect,
  ElementRef,
  inject,
  input,
  model,
  Renderer2,
  signal,
  viewChild,
} from '@angular/core';
import { IconComponent } from '@shared/components/icon/icon.component';
import { BtnDirective } from '@shared/directives/button/btn.directive';

@Component({
  selector: 'vk-dialog',
  standalone: true,
  imports: [CommonModule, IconComponent, BtnDirective],
  templateUrl: './dialog.component.html',
  styleUrl: './dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogComponent {
  readonly #renderer = inject(Renderer2);
  readonly #document = inject(DOCUMENT);

  modalOpened = model.required<boolean>({ alias: 'opened' });
  fullHeight = input(false, { transform: booleanAttribute });

  readonly closeIconHovered = signal<boolean>(false);
  readonly dialogRef = viewChild<ElementRef<HTMLDialogElement>>('dialog');

  constructor() {
    effect(() => {
      if (this.modalOpened()) {
        this.#renderer.setAttribute(this.#document.body, 'class', 'dialog-opened');
        this.dialogRef() ? this.dialogRef()!.nativeElement.showModal() : null;
      } else {
        this.#renderer.removeClass(this.#document.body, 'dialog-opened');
        this.dialogRef() ? this.dialogRef()!.nativeElement.close() : null;
      }
    });
  }

  onDialogClose($event: MouseEvent): void {
    $event.preventDefault();
    this.modalOpened.set(false);
  }

  onCancel($event: KeyboardEvent): void {
    $event.preventDefault();

    if ($event.key === 'Escape') {
      this.modalOpened.set(false);
    }
  }
}
