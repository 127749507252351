import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { ROUTES_FULL_PATHS } from '@core/routes/app.route-config.token';
import { TagsService } from '@core/services/tags/tags.service';
import { ApiResponse, ProgramCard, ProgramResponse } from '@shared/types';
import { LocalizeService } from '@trendency/ng-translate';
import { catchError, map, Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProgramsService {
  readonly #http: HttpClient = inject(HttpClient);
  readonly #tagsService = inject(TagsService);
  readonly #localizeService = inject(LocalizeService);
  readonly #routesFullPaths = inject(ROUTES_FULL_PATHS);

  getProgramRecommendations$(): Observable<ProgramCard[]> {
    return this.#http.get<ApiResponse<ProgramResponse[]>>('program/recommended').pipe(
      map((res: ApiResponse<ProgramResponse[]>) =>
        res.data.map((program) => ({
          ...program,
          tags: this.#tagsService.tagsWithRoute(program.tags, this.#routesFullPaths.PROGRAMS),
          url: this.#localizeService.translateRouteToCurrentLanguage(this.#routesFullPaths.PROGRAMS.concat(program.slug)),
          location: { name: 'Lovarda', address: '1014 Budapest, Szent György u., Hunyadi udvar, Csikós udvar' },
          date: new Date(program.date),
          imageUrl: program.media?.url || '',
          imageAlt: program.media?.title || '',
        }))
      ),
      catchError((error: HttpErrorResponse) => {
        return throwError(() => error);
      })
    );
  }
}
