@defer (on viewport; on timer(5s)) {
  <dialog class="dialog" #dialog [ngClass]="{ 'full-height': fullHeight() }" (keydown)="onCancel($event)">
    <button class="dialog-close-btn" (click)="onDialogClose($event)">
      <vk-icon class="dialog-close-icon" [size]="32" name="icon-close" />
    </button>
    <ng-content></ng-content>
  </dialog>
} @placeholder {
  <!--dialog only available on client side-->
  <div></div>
}
