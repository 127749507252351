import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, input, OnInit, ViewEncapsulation } from '@angular/core';
import { ControlContainer, FormControl, FormGroup, NonNullableFormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { ClientValidationMessagesComponent } from '@shared/components/forms/errors/client-validation-messages/client-validation-messages.component';

export type SelectOption = {
  readonly label: string;
  readonly value: string;
};

@Component({
  selector: 'vk-select',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, NgSelectModule, ClientValidationMessagesComponent],
  templateUrl: './select.component.html',
  styleUrl: './select.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class SelectComponent implements OnInit {
  options = input.required<ReadonlyArray<SelectOption>>();
  label = input<string>();
  controlName = input.required<string>();
  required = input<boolean>(false);

  readonly #fb = inject(NonNullableFormBuilder);
  readonly #parentContainer = inject(ControlContainer);

  get form(): FormGroup {
    return this.#parentContainer.control as FormGroup;
  }

  get formControl(): FormControl<boolean> {
    return this.form.get(this.controlName()) as FormControl<boolean>;
  }

  ngOnInit(): void {
    if (!this.form.contains(this.controlName())) {
      this.form.addControl(this.controlName(), this.#fb.control('', this.required() ? Validators.required : []));
    }
  }
}
