<article class="card">
  <img class="card-image article-card-image" [src]="article().media?.url" [alt]="article().title" />
  <div class="card-content">
    @if (showDetails()) {
      <time class="card-info"> {{ article().publishedAt | date: dateFormat() : '' : locale() }}</time>
    }
    <h3 class="card-title">{{ article().title }}</h3>
    @if (article().tags; as tags) {
      <vk-tag-list [tags]="tags" card />
    }
    @if (buttonText(); as buttonText) {
      <a [routerLink]="article().url" vkBtn="tertiary" background="white"> {{ buttonText }} <vk-icon name="icon-arrow-right" /> </a>
    }
  </div>
</article>
