import { ChangeDetectionStrategy, Component, HostBinding, input } from '@angular/core';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'vk-image-banner',
  standalone: true,
  imports: [RouterLink],
  templateUrl: './image-banner.component.html',
  styleUrl: './image-banner.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageBannerComponent {
  imageUrl = input.required<string>();
  slug = input.required<string>();
  color = input.required<string>();
  imageAlt = input<string | undefined>();

  @HostBinding('style.border-color') get borderColor(): string {
    return this.color();
  }
}
