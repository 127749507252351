<vk-media-with-content-block [image]="image()" [video]="video()" [mediaPlace]="mediaPlace()">
  <div class="cta-with-media-block-container">
    <h2 class="cta-with-media-block-title">{{ title() }}</h2>
    @if (description()) {
      <p class="cta-with-media-block-description" [innerHTML]="description()"></p>
    }
    <a class="cta-with-media-block-btn" [scheme]="buttonStyle().sheme" [routerLink]="button().url" [vkBtn]="buttonStyle().theme">
      {{ button().title }}
    </a>
  </div>
</vk-media-with-content-block>
