<div class="radio-wrapper" [formGroup]="form" [ngClass]="{ 'is-disabled': formControl.disabled }">
  @for (option of options(); track $index) {
    <label class="radio-label">
      <input class="radio" [value]="option.value" [formControlName]="controlName()" type="radio" />
      <p>{{ option.label }}</p>
    </label>
  }
</div>
<div>
  @if (formControl.invalid && formControl.touched) {
    <vk-client-validation-messages [errors]="formControl.errors"></vk-client-validation-messages>
  }
</div>
