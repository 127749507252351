import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, HostBinding, input, signal } from '@angular/core';
import { DefaultBlockComponent } from '@shared/components/default-block/default-block.component';
import { IconComponent } from '@shared/components/icon/icon.component';
import { BtnDirective } from '@shared/directives';
import {
  BlockButton,
  BlockButtonShemeStyles,
  BlockButtonStyle,
  BlockImage,
  DefaultContent,
  hexColorRegex,
  TextColorStyles,
} from '@shared/types';

@Component({
  selector: 'vk-sight-brand-color-bg-block',
  standalone: true,
  imports: [CommonModule, BtnDirective, IconComponent, DefaultBlockComponent],
  templateUrl: './sight-brand-color-bg-block.component.html',
  styleUrl: './sight-brand-color-bg-block.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SightBrandColorBgBlockComponent {
  title = input.required<string>();
  descriptionBlocks = input.required<ReadonlyArray<DefaultContent>>();
  image = input.required<BlockImage>();
  button = input.required<BlockButton>();
  logoUrl = input.required<string>();
  backgroundColor = input.required({
    transform: (color: string | undefined): string => (hexColorRegex.test(color ?? '') ? (color as string) : this.#defaultBackgroundColor),
  });
  textColorStyle = input<TextColorStyles>('light');
  buttonColorStyle = input<BlockButtonShemeStyles>('dark');

  readonly buttonStyles = signal<{ [key in BlockButtonShemeStyles]: BlockButtonStyle }>({
    dark: {
      theme: 'primary',
      sheme: 'dark',
    },
    gold: {
      theme: 'primary',
      sheme: 'gold',
    },
    white: {
      theme: 'primary',
      sheme: 'white',
    },
  });
  readonly buttonStyle = computed(() => this.buttonStyles()[this.buttonColorStyle()]);

  readonly #defaultBackgroundColor = '#0b2355';

  @HostBinding('style.--sight-brand-color-bg-block-background-color') get backgroundColorVar(): string {
    return this.backgroundColor();
  }

  @HostBinding('style.--sight-brand-color-bg-block-text-color') get textColorVar(): string {
    return `var(--sight-brand-color-bg-block-${this.textColorStyle()}-text-color)`;
  }
}
