import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, input } from '@angular/core';
import { RouterLink } from '@angular/router';
import { DefaultBlockComponent } from '@shared/components/default-block/default-block.component';
import { MediaWithContentBlockComponent } from '@shared/components/media-with-content-block/media-with-content-block.component';
import { BlockImage, BlockMediaPlace, DefaultContent } from '@shared/types';

@Component({
  selector: 'vk-full-height-media-block',
  standalone: true,
  imports: [CommonModule, RouterLink, DefaultBlockComponent, MediaWithContentBlockComponent],
  templateUrl: './full-height-media-block.component.html',
  styleUrl: './full-height-media-block.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FullHeightMediaBlockComponent {
  title = input.required<string>();
  description = input.required<ReadonlyArray<DefaultContent>>();
  image = input.required<BlockImage>();
  mediaPlace = input.required<BlockMediaPlace>();
  mediaColumnGap = input<number>(120);
  mediaRowGap = input<number>(24);
  contentWidth = input<number>(459);
  blockGap = input<number>(8);
  textGap = input<number>(8);

  @HostBinding('style.--default-block-max-width') get contentWidthVar(): string {
    return `${this.contentWidth()}px`;
  }
}
