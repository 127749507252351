import { CurrencyPipe, DatePipe } from '@angular/common';
import { booleanAttribute, ChangeDetectionStrategy, Component, HostBinding, input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { IconComponent } from '@shared/components/icon/icon.component';
import { TagListComponent } from '@shared/components/tag-list/tag-list.component';
import { BtnDirective } from '@shared/directives/button/btn.directive';
import { ProgramCard } from '@shared/types/card.types';
import { localeTransform } from '@shared/utils/localeInputTranformer';

@Component({
  selector: 'vk-program-card',
  standalone: true,
  imports: [BtnDirective, CurrencyPipe, DatePipe, IconComponent, RouterModule, TagListComponent],
  templateUrl: './program-card.component.html',
  styleUrl: './program-card.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProgramCardComponent {
  program = input.required<ProgramCard>();
  locale = input.required({ transform: localeTransform, alias: 'lang' });
  buttonText = input.required<string>();
  dateFormat = input<string>('YYYY. MMMM dd. EEEE, HH:mm');
  smallCard = input(false, { transform: booleanAttribute });
  isMobile = input(false, { transform: booleanAttribute });

  @HostBinding('class.small-card') get smallCardClass(): boolean {
    return this.smallCard();
  }
}
