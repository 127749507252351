import { ChangeDetectionStrategy, Component, HostBinding, input } from '@angular/core';
import { TextBlockBackgrondStyles } from '@shared/types';

@Component({
  selector: 'vk-text-block',
  standalone: true,
  templateUrl: './text-block.component.html',
  styleUrl: './text-block.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextBlockComponent {
  title = input.required<string>();
  description = input<string | undefined>();
  backgroundColorStyle = input<TextBlockBackgrondStyles>('light');

  @HostBinding('style.--text-block-backround-color') get backgroundColorVar(): string {
    console.log('this.backgroundColorStyle()', this.backgroundColorStyle());
    return `var(--text-block-${this.backgroundColorStyle()}-backround-color)`;
  }
}
