<div [formGroup]="form">
  <input class="tag-checkbox" [id]="controlName()" [formControlName]="controlName()" type="checkbox" />
  <label class="tag-checkbox-label" [for]="controlName()">
    @if (iconPosition() === 'left') {
      <vk-icon class="tag-checkbox-icon" [name]="iconName()" />
    }
    {{ label() }}
    @if (iconPosition() === 'right') {
      <vk-icon class="tag-checkbox-icon" [name]="iconName()" />
    }
  </label>
</div>
