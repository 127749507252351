import { ChangeDetectionStrategy, Component, computed, HostBinding, input, signal } from '@angular/core';
import { RouterLink } from '@angular/router';
import { IconComponent } from '@shared/components/icon/icon.component';
import { MediaWithContentBlockComponent } from '@shared/components/media-with-content-block/media-with-content-block.component';
import { VideoComponent } from '@shared/components/video/video.component';
import { BtnDirective } from '@shared/directives';
import {
  BlockButton,
  BlockButtonShemeStyles,
  BlockButtonStyle,
  BlockImage,
  BlockMediaPlace,
  BlockVideo,
  CtaBlockBackgrondStyles,
} from '@shared/types';

@Component({
  selector: 'vk-cta-with-media-block',
  standalone: true,
  imports: [RouterLink, MediaWithContentBlockComponent, IconComponent, VideoComponent, BtnDirective],
  templateUrl: './cta-with-media-block.component.html',
  styleUrl: './cta-with-media-block.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CtaWithMediaBlockComponent {
  title = input.required<string>();
  button = input.required<BlockButton>();
  description = input<string | undefined>();
  image = input.required<BlockImage | undefined>();
  videoUrl = input.required<string | undefined>();
  mediaPlace = input<BlockMediaPlace>('right');
  backgroundColorStyle = input<CtaBlockBackgrondStyles>('dark');

  readonly video = computed<BlockVideo | undefined>(() => {
    return this.videoUrl()
      ? ({
          url: this.videoUrl(),
          cssHeight: '100%',
          clipPathType: 'none',
        } as BlockVideo)
      : undefined;
  });

  readonly buttonStyles = signal<{ [key in BlockButtonShemeStyles]: BlockButtonStyle }>({
    dark: {
      theme: 'primary',
      sheme: 'dark',
    },
    gold: {
      theme: 'primary',
      sheme: 'gold',
    },
    white: {
      theme: 'primary',
      sheme: 'white',
    },
  });

  readonly buttonStyle = computed(() => this.buttonStyles()[this.backgroundColorStyle()]);

  @HostBinding('style.--cta-with-media-block-backround-color') get backgroundColorVar(): string {
    return `var(--cta-with-media-block-${this.backgroundColorStyle()}-backround-color)`;
  }

  @HostBinding('style.--cta-with-media-block-text-color') get textColorVar(): string {
    return `var(--cta-with-media-block-${this.backgroundColorStyle()}-bg-text-color)`;
  }
}
