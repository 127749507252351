import { booleanAttribute, ChangeDetectionStrategy, Component, HostBinding, input } from '@angular/core';
import { RouterLink } from '@angular/router';
import { IconComponent } from '@shared/components/icon/icon.component';

@Component({
  selector: 'vk-tag',
  standalone: true,
  imports: [IconComponent, RouterLink],
  templateUrl: './tag.component.html',
  styleUrl: './tag.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagComponent {
  title = input.required<string>();
  url = input<string>();
  card = input(false, { transform: booleanAttribute });

  @HostBinding('class.small') get small(): boolean {
    return this.card();
  }
}
