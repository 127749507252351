import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, input, signal } from '@angular/core';
import { CarouselComponent } from '@shared/components/carousel/carousel.component';
import { CarouselItemDirective } from '@shared/components/carousel/directives/carousel-item.directive';
import { DialogComponent } from '@shared/components/dialog/dialog.component';
import { IconComponent } from '@shared/components/icon/icon.component';
import { BtnDirective } from '@shared/directives/button/btn.directive';
import { ZoomPanClampImagePreviewDirective } from '@shared/directives/zoom-pan-clamp-image-preview/zoom-pan-clamp-image-preview.directive';
import { BlockButton, BlockImage } from '@shared/types';

@Component({
  selector: 'vk-dialog-full-screen-zoom-image-block',
  standalone: true,
  imports: [
    CommonModule,
    DialogComponent,
    CarouselComponent,
    CarouselItemDirective,
    IconComponent,
    BtnDirective,
    ZoomPanClampImagePreviewDirective,
  ],
  templateUrl: './dialog-full-screen-zoom-image-block.component.html',
  styleUrl: './dialog-full-screen-zoom-image-block.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogFullScreenZoomImageBlockComponent {
  image = input.required<BlockImage>();
  button = input.required<BlockButton>();

  readonly dialogOpened = signal<boolean>(false);

  onDialogOpen($event: MouseEvent): void {
    $event.preventDefault();
    this.dialogOpened.set(true);
  }
}
