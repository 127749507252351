import { Directive, HostBinding, Input } from '@angular/core';

export type BtnTheme = 'primary' | 'secondary' | 'tertiary';
export type BtnScheme = 'white' | 'gold' | 'dark';

@Directive({
  selector: 'button[vkBtn], a[vkBtn]',
  standalone: true,
})
export class BtnDirective {
  #theme: BtnTheme = 'primary';
  #scheme: BtnScheme = 'white';
  @HostBinding('class') protected classes = 'btn btn-primary white-bg';

  readonly #updateClasses = (): void => {
    this.classes = `btn ${this.#getTheme(this.#theme)} ${this.#getScheme(this.#scheme)}`;
  };

  readonly #getTheme = (theme: BtnTheme): 'btn-primary' | 'btn-secondary' | 'btn-tertiary' => {
    switch (theme) {
      case 'primary':
        return 'btn-primary';
      case 'secondary':
        return 'btn-secondary';
      case 'tertiary':
        return 'btn-tertiary';
    }
  };

  readonly #getScheme = (scheme: BtnScheme): 'white-scheme' | 'gold-scheme' | 'dark-scheme' => {
    switch (scheme) {
      case 'white':
        return 'white-scheme';
      case 'gold':
        return 'gold-scheme';
      case 'dark':
        return 'dark-scheme';
    }
  };

  @Input()
  set vkBtn(value: BtnTheme | '') {
    this.#theme = value === '' ? 'primary' : value;
    this.#updateClasses();
  }

  @Input()
  set scheme(value: BtnScheme) {
    this.#scheme = value;
    this.#updateClasses();
  }
}
