<div class="swiper">
  <swiper-container #swiper init="false">
    @for (item of data(); track $index; let first = $first; let last = $last) {
      <swiper-slide class="swiper-slide" [ngClass]="{ first: first && hasFirstItemOffset(), last: last && hasLastItemOffset() }">
        <ng-container
          *ngTemplateOutlet="
            itemContentChildTemplate() || itemTemplate() || defaultItemTemplate;
            context: { $implicit: item, index: $index }
          "
        >
        </ng-container>
      </swiper-slide>
    }
  </swiper-container>
</div>

<div class="swiper-navigation-container full-width">
  <div class="swiper-navigation">
    <div class="swiper-scrollbar" #scrollbar>
      <div class="swiper-scrollbar-drag"></div>
    </div>
    <button class="btn-control left" (click)="swiper.swiper.slidePrev()" vkBtn><vk-icon name="icon-arrow-left" /></button>
    <button class="btn-control right" (click)="swiper.swiper.slideNext()" vkBtn><vk-icon name="icon-arrow-right" /></button>
  </div>
</div>

<ng-template #defaultItemTemplate [vkCarouselItem]="data()" let-index="index">
  <p data-playwright-error>Provide template, slide {{ index + 1 }} / {{ data().length }}</p>
</ng-template>
