<div class="card">
  @if (program(); as program) {
    <img class="card-image program-card-image" [src]="program.imageUrl" [alt]="program.title" />
    <div class="card-content">
      @if (!isMobile() || !smallCard()) {
        <div class="program-card-details card-info">
          <span>{{ program.location.name }}</span>
          <time>{{ program.date | date: dateFormat() : '' : locale() }}</time>
        </div>
      }

      <vk-tag-list [tags]="program.tags" card />
      <div>
        <h3 class="card-title">{{ program.title }}</h3>
        <p class="card-lead">{{ program.lead }}</p>
      </div>

      @if (smallCard() && isMobile()) {
        <div class="program-card-details card-info">
          <span>{{ program.location.name }}</span>
          <time>{{ program.date | date: dateFormat() : '' : locale() }}</time>
        </div>
      }

      <div class="program-card-bottom-container">
        <span class="program-card-price">{{ program.price.price | currency: 'Ft' : 'symbol' : '1.0' }}</span
        ><a [vkBtn]="'tertiary'" [routerLink]="program.url">{{ buttonText() }} <vk-icon name="icon-arrow-right" /></a>
      </div>
    </div>
  }
</div>
