import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { FeaturedNavigationCardComponent } from '@shared/components/featured-navigation-card/featured-navigation-card.component';
import { FeaturedNavCard } from '@shared/components/featured-navigation-card/types/featured-navigation-card.types';

@Component({
  selector: 'vk-featured-navigation-card-list',
  standalone: true,
  imports: [FeaturedNavigationCardComponent],
  templateUrl: './featured-navigation-card-list.component.html',
  styleUrl: './featured-navigation-card-list.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FeaturedNavigationCardListComponent {
  cards = input<readonly FeaturedNavCard[]>([]);
}
