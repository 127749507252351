<iframe
  width="640"
  height="360"
  src="https://www.youtube.com/embed/ezP4kbOvs_E"
  title="YouTube video player"
  frameborder="0"
  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
  allowfullscreen
  style="border-radius: 12px"
></iframe>
