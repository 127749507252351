import { DatePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { IconComponent } from '@shared/components/icon/icon.component';
import { TagListComponent } from '@shared/components/tag-list/tag-list.component';
import { Tag } from '@shared/types/tag.types';
import { localeTransform } from '@shared/utils/localeInputTranformer';

@Component({
  selector: 'vk-featured-article-card',
  standalone: true,
  imports: [RouterModule, DatePipe, TagListComponent, IconComponent],
  templateUrl: './featured-article-card.component.html',
  styleUrl: './featured-article-card.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FeaturedArticleCardComponent {
  title = input.required<string>();
  publishedAt = input.required<string>();
  tags = input.required<readonly Tag[]>();
  slug = input.required<string>();
  url = input.required<string>();
  locale = input.required({ transform: localeTransform, alias: 'lang' });
  dateFormat = input<string>('y. MMMM d.');
  media = input<{ readonly url: string } | undefined>();
}
