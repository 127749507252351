import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, effect, inject, input, OnInit } from '@angular/core';
import { ControlContainer, FormControl, FormGroup, NonNullableFormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { ClientValidationMessagesComponent } from '@shared/components/forms/errors/client-validation-messages/client-validation-messages.component';

@Component({
  selector: 'vk-checkbox',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, ClientValidationMessagesComponent],
  templateUrl: './checkbox.component.html',
  styleUrl: './checkbox.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckboxComponent implements OnInit {
  controlName = input.required<string>();
  value = input<boolean>(false);
  required = input<boolean>(false);
  disabled = input<boolean>(false);
  iconName = input<string>('icon-close');
  iconPosition = input<'left' | 'right'>('right');

  readonly #fb = inject(NonNullableFormBuilder);
  readonly #parentContainer = inject(ControlContainer);

  constructor() {
    effect(() => {
      if (this.disabled()) {
        this.formControl.disable();
      } else {
        this.formControl.enable();
      }
    });
  }

  get form(): FormGroup {
    return this.#parentContainer.control as FormGroup;
  }

  get formControl(): FormControl<string | null> {
    return this.form.get(this.controlName()) as FormControl<string | null>;
  }

  ngOnInit(): void {
    if (!this.form.contains(this.controlName())) {
      this.form.addControl(this.controlName(), this.#fb.control(this.value(), this.required() ? Validators.requiredTrue : []));
    }
  }
}
