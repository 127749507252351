<div class="input-wrapper" [formGroup]="form" [ngClass]="{ 'is-disabled': formControl.disabled }">
  <vk-label [label]="label()" [isError]="formControl.invalid && formControl.touched && formControl.dirty && !focused()">
    <div class="input-block">
      <input
        class="input"
        [value]="value()"
        [ngClass]="{ 'has-icon': iconName() }"
        [type]="type()"
        [placeholder]="placeholder()"
        [formControlName]="controlName()"
        [vkAutoFocus]="autoFocus()"
        (blur)="onBlur()"
        (focus)="onFocus()"
      />
      @if (iconName(); as iconName) {
        <button
          class="input-icon-button"
          [ngClass]="{ 'has-error': formControl.invalid && formControl.touched && formControl.dirty && !focused() }"
          (click)="emitClick()"
          type="button"
        >
          <vk-icon [name]="iconName"></vk-icon>
        </button>
      }
    </div>
  </vk-label>
  @if (description()) {
    <p class="input-description">{{ description() }}</p>
  }
</div>

@if (checkPasswordStrength()) {
  <vk-password-strength-validator [inputErrors]="formControl.errors"></vk-password-strength-validator>
}
@if (formControl.invalid && formControl.touched && formControl.dirty && !focused()) {
  <div>
    <vk-client-validation-messages [errors]="formControl.errors"></vk-client-validation-messages>
  </div>
}
