import { ChangeDetectionStrategy, Component, HostBinding, inject, input } from '@angular/core';
import { BreakpointState } from '@core/states/breakpoint/breakpoint.state';
import { LocalizeState } from '@core/states/localize/localize.state';
import { NewsArticleState } from '@features/news/components/news-article/states/news-article.state';
import { FULL_WIDTH } from '@shared/constants';

import { ArticleCardListComponent } from '../article-card-list.component';

@Component({
  selector: 'vk-recommended-article-card-list-wrapper',
  standalone: true,
  imports: [ArticleCardListComponent],
  templateUrl: './recommended-article-card-list-wrapper.component.html',
  styleUrl: './recommended-article-card-list-wrapper.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RecommendenArticleCardListWrapperComponent {
  @HostBinding(FULL_WIDTH) readonly fullWidth = true;

  buttonText = input.required<string>();

  readonly localizeState = inject(LocalizeState);
  readonly newsArticleState = inject(NewsArticleState);
  readonly breakpointState = inject(BreakpointState);

  constructor() {
    this.newsArticleState.fetchRecommendedArticles();
  }
}
