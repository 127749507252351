import { Injectable, Signal } from '@angular/core';
import { patchState, signalState } from '@ngrx/signals';

type TwoCalendarDateRangeInlinePickerType<D> = {
  readonly leftCurrentDate: D | null;
  readonly rightCurrentDate: D | null;
};

const initalState = {
  leftCurrentDate: null,
  rightCurrentDate: null,
};

@Injectable({
  providedIn: 'root',
})
export class TwoCalendarDateRangeInlinePickerState<D> {
  readonly #twoCalendarDateRangeInlinePickerState = signalState<TwoCalendarDateRangeInlinePickerType<D>>(initalState);

  get leftCurrentDate(): Signal<D | null> {
    return this.#twoCalendarDateRangeInlinePickerState.leftCurrentDate;
  }

  set leftCurrentDate(date: D) {
    patchState(this.#twoCalendarDateRangeInlinePickerState, { leftCurrentDate: date });
  }

  get rightCurrentDate(): Signal<D | null> {
    return this.#twoCalendarDateRangeInlinePickerState.rightCurrentDate;
  }

  set rightCurrentDate(date: D) {
    patchState(this.#twoCalendarDateRangeInlinePickerState, { rightCurrentDate: date });
  }
}
