import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { IconComponent } from '@shared/components/icon/icon.component';

@Component({
  selector: 'vk-featured-navigation-card',
  standalone: true,
  imports: [RouterModule, IconComponent],
  templateUrl: './featured-navigation-card.component.html',
  styleUrl: './featured-navigation-card.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FeaturedNavigationCardComponent {
  title = input.required<string>();
  slug = input.required<string>();
  imageUrl = input.required<string>();
  description = input<string | undefined>();
}
