<section class="default-block">
  @for (block of blocks(); track $index) {
    @if (acceptedBlockTypes().includes(block.type) || acceptedBlockTypes() === 'all') {
      @switch (block.type) {
        @case ('header') {
          @switch (block.data.level) {
            @case (1) {
              <h1 class="default-header">{{ block.data.text }}</h1>
            }
            @case (2) {
              <h2 class="default-header">{{ block.data.text }}</h2>
            }
            @case (3) {
              <h3 class="default-header">{{ block.data.text }}</h3>
            }
            @case (4) {
              <h4 class="default-header">{{ block.data.text }}</h4>
            }
            @default {
              <h4 class="default-header">{{ block.data.text }}</h4>
            }
          }
        }
        @case ('paragraph') {
          <p class="default-paragraph is-alternative" [innerHTML]="block.data.text"></p>
        }
        @case ('delimiter') {
          <hr class="default-delimiter" />
        }
        @case ('list') {
          @if (block.data.style === 'ordered') {
            <ol class="default-list">
              @for (item of block.data.items; track item) {
                <li class="default-list-item">{{ item }}</li>
              }
            </ol>
          } @else {
            <ul class="default-list">
              @for (item of block.data.items; track item) {
                <li class="default-list-item">{{ item }}</li>
              }
            </ul>
          }
        }
        @case ('image') {
          <figure class="default-figure">
            <img class="default-image" [src]="block.data.file.url" [alt]="block.data.caption" />
            @if (block.data.caption) {
              <figcaption>
                <p class="default-caption">{{ block.data.caption }}</p>
              </figcaption>
            }
          </figure>
        }
      }
    }
  }
</section>
