import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, input } from '@angular/core';
import { IconComponent } from '@shared/components/icon/icon.component';
import { BtnDirective } from '@shared/directives';
import { BlockButton, BlockImage, TextColorStyles } from '@shared/types';

@Component({
  selector: 'vk-sight-brand-block',
  standalone: true,
  imports: [CommonModule, BtnDirective, IconComponent],
  templateUrl: './sight-brand-block.component.html',
  styleUrl: './sight-brand-block.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SightBrandBlockComponent {
  title = input.required<string>();
  description = input.required<string>();
  image = input.required<BlockImage>();
  button = input.required<BlockButton>();
  logoUrl = input.required<string>();
  backgroundColor = input.required({
    transform: (color: string | undefined): string =>
      this.#backgroundColorRegex.test(color ?? '') ? (color as string) : this.defaultBackgroundColor,
  });
  textColorStyle = input<TextColorStyles>('light');

  readonly #backgroundColorRegex = /^#?([0-9a-f]{6}|[0-9a-f]{3});$/;
  readonly defaultBackgroundColor = '#0b2355';

  @HostBinding('style.--sight-block-background-color') get backgroundColorVar(): string {
    return this.backgroundColor();
  }

  @HostBinding('style.--sight-block-text-color') get textColorVar(): string {
    return `var(--sight-block-${this.textColorStyle()}-text-color)`;
  }
}
