import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, input, OnInit } from '@angular/core';
import { ControlContainer, FormControl, FormGroup, NonNullableFormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { LabelComponent } from '@shared/components/forms/components/label/label.component';
import { ClientValidationMessagesComponent } from '@shared/components/forms/errors/client-validation-messages/client-validation-messages.component';
import { IconComponent } from '@shared/components/icon/icon.component';

@Component({
  selector: 'vk-textarea',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, ClientValidationMessagesComponent, LabelComponent, IconComponent],
  templateUrl: './textarea.component.html',
  styleUrl: './textarea.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextareaComponent implements OnInit {
  controlName = input.required<string>();
  label = input<string>();
  value = input<string | null>(null);
  rows = input<number>(3);
  placeholder = input<string>('');
  required = input<boolean>(false);

  readonly #fb = inject(NonNullableFormBuilder);
  readonly #parentContainer = inject(ControlContainer);

  get form(): FormGroup {
    return this.#parentContainer.control as FormGroup;
  }

  get formControl(): FormControl<string> {
    return this.form.get(this.controlName()) as FormControl<string>;
  }

  ngOnInit(): void {
    if (!this.form.contains(this.controlName())) {
      this.form.addControl(this.controlName(), this.#fb.control('', this.required() ? Validators.required : []));
    }
  }
}
