import { ChangeDetectionStrategy, Component, HostBinding, input } from '@angular/core';
import { JotFormWithContentBlockComponent } from '@shared/components/jot-form-with-content-block/jot-form-with-content-block.component';
import { BlockMediaPlace, hexColorRegex } from '@shared/types';

@Component({
  selector: 'vk-jot-form-contact-with-text-block',
  standalone: true,
  imports: [JotFormWithContentBlockComponent],
  templateUrl: './jot-form-contact-with-text-block.component.html',
  styleUrl: './jot-form-contact-with-text-block.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JotFormContactWithTextBlockComponent {
  title = input.required<string>();
  description = input<string | undefined>();
  jotId = input.required<string>();
  formHeight = input.required({ transform: (value: number | undefined): number => (value ? (value as number) : this.#defaultFormHeight) });
  formPlace = input<BlockMediaPlace>('right');
  backgroundColor = input.required({
    transform: (color: string | undefined): string => (hexColorRegex.test(color ?? '') ? (color as string) : this.#defaultBackgroundColor),
  });
  textBGColorStyle = input<'dark' | 'light'>('light');

  readonly #defaultBackgroundColor = 'white';
  readonly #defaultFormHeight = 1300;

  @HostBinding('style.--jot-form-contact-with-text-block-background-color') get backgroundColorVar(): string {
    return this.backgroundColor();
  }

  @HostBinding('style.--content-text-color') get textColorVar(): string {
    return `var(--content-${this.textBGColorStyle()}-bg-text-color)`;
  }
}
