import { animate, state, style, transition, trigger } from '@angular/animations';
import { CommonModule } from '@angular/common';
import {
  afterNextRender,
  AfterRenderPhase,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  input,
  signal,
  viewChild,
} from '@angular/core';
import { IconComponent } from '@shared/components/icon/icon.component';
import { BlockCornerClip } from '@shared/types';

@Component({
  selector: 'vk-video',
  standalone: true,
  imports: [CommonModule, IconComponent],
  templateUrl: './video.component.html',
  styleUrl: './video.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('fade', [
      state(
        'visible',
        style({
          opacity: 1,
        })
      ),
      state(
        'hidden',
        style({
          opacity: 0.7,
        })
      ),
      transition('visible => hidden', [animate('600ms ease-out')]),
      transition('hidden => visible', [animate('600ms ease-in')]),
    ]),
  ],
})
export class VideoComponent {
  fadeState = 'hidden';
  videoUrl = input<string>('');
  clipPathType = input<BlockCornerClip>('small');
  animation = input<string | null>(null);
  videoHeight = input<string | null | undefined>(null);
  videoWidth = input<string | null | undefined>(null);

  readonly videoPlayerRef = viewChild.required<ElementRef<HTMLVideoElement>>('player');

  readonly percentage = signal<number>(0);
  readonly iconName = signal<string>('pause');

  readonly HUNDRED_PERCENT = 100;

  constructor() {
    afterNextRender(
      () => {
        if (this.videoUrl() !== '') {
          setTimeout(() => {
            const currentlyPlaying = !this.#videoPlayer.paused && !this.#videoPlayer.ended;
            this.#videoPlayer.muted = true;
            !currentlyPlaying ? this.#videoPlayer.play().catch(() => null) : null;
            this.fadeState = 'visible';
          }, 500);
        }
      },
      { phase: AfterRenderPhase.Read }
    );
  }

  @HostBinding('style.--video-height') get videoHeightStyle(): string | null {
    return this.videoHeight() as string | null;
  }

  @HostBinding('style.--video-width') get videoWidthStyle(): string | null {
    return this.videoWidth() as string | null;
  }

  onVideoEnd(): void {
    this.#videoPlayer.currentTime = 0;
    this.#videoPlayer.play().catch(() => null);
    this.fadeState = 'visible';
  }

  get #videoPlayer(): HTMLVideoElement {
    return this.videoPlayerRef().nativeElement;
  }

  playPauseVideo(): void {
    const currentlyPlaying = !this.#videoPlayer.paused && !this.#videoPlayer.ended;
    this.iconName.set(currentlyPlaying ? 'play' : 'pause');
    currentlyPlaying ? this.#videoPlayer.pause() : this.#videoPlayer.play().catch(() => null);
  }

  updateProgressBar(): void {
    if (!isNaN(this.#videoPlayer.duration)) {
      const percentage = Math.floor((this.HUNDRED_PERCENT / this.#videoPlayer.duration) * this.#videoPlayer.currentTime);
      if (percentage >= 97) {
        this.fadeState = 'hidden';
      }

      this.percentage.set(percentage);
    }
  }
}
