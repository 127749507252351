import { computed, DestroyRef, inject, Injectable, Signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Event, NavigationStart, Router } from '@angular/router';
import { patchState, signalState } from '@ngrx/signals';
import { LocalizeService } from '@trendency/ng-translate';
import { AvailableLangs, availableLangs } from 'src/environments';

export type LocalizeStateType = {
  readonly lang: AvailableLangs;
  readonly langChanged: boolean;
  readonly redirecUrlWhenLangChanged: string[] | null;
  readonly callbackFnWhenLangChanged: (() => void) | null;
};

const initialLocalizeState: LocalizeStateType = {
  lang: 'hu',
  langChanged: false,
  redirecUrlWhenLangChanged: null,
  callbackFnWhenLangChanged: null,
};

@Injectable({
  providedIn: 'root',
})
export class LocalizeState {
  readonly #router = inject(Router);
  readonly #destroyRef = inject(DestroyRef);
  readonly #localizeService = inject(LocalizeService);
  readonly #localizeState = signalState<LocalizeStateType>({
    ...initialLocalizeState,
    lang: this.#localizeService.currentLanguage as AvailableLangs,
  });

  readonly lang = this.#localizeState.lang;
  readonly langChanged: Signal<boolean> = this.#localizeState.langChanged;
  readonly otherLang: Signal<AvailableLangs> = computed(() => availableLangs.find((lang) => lang !== this.lang()) as AvailableLangs);

  changeLanguage = (lang: AvailableLangs): void => {
    if (this.#localizeState.redirecUrlWhenLangChanged() !== null && this.#localizeState.redirecUrlWhenLangChanged()!.length > 0) {
      this.#router.navigate([this.#localizeService.translateRoute(this.#localizeState.redirecUrlWhenLangChanged() as string[], lang)]);
    } else {
      this.#localizeService.changeLanguage(lang, { preserveQueryParams: true });
    }
  };

  createExactlyLocalizedUrlBySlug = (slug: string): string =>
    (slug.slice(0, 1) !== '/' ? '/' : '') + (this.lang() === 'hu' ? '' : `${this.lang()}/`) + slug;

  constructor() {
    this.#router.events.pipe(takeUntilDestroyed(this.#destroyRef)).subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        patchState(this.#localizeState, { langChanged: false, redirecUrlWhenLangChanged: null });
      }
    });

    this.#localizeService.currentLanguage$.pipe(takeUntilDestroyed(this.#destroyRef)).subscribe((lang) => {
      patchState(this.#localizeState, { lang: lang as AvailableLangs, langChanged: true });
    });
  }

  set redirecUrlWhenLangChanged(redirecUrlWhenLangChanged: string[]) {
    patchState(this.#localizeState, { redirecUrlWhenLangChanged });
  }
}
