import { inject, Injectable } from '@angular/core';
import { ROUTES_FULL_PATHS } from '@core/routes/app.route-config.token';
import { LocalizeState } from '@core/states/localize/localize.state';
import { NewsService } from '@features/news/services/news.service';
import { tapResponse } from '@ngrx/operators';
import { patchState, signalState } from '@ngrx/signals';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { ArticleCard, NewsArticle } from '@shared/types';
import { exhaustMap, pipe, tap } from 'rxjs';

type NewsArticleStateType = {
  readonly article: NewsArticle | undefined;
  readonly recommendedArticles: readonly ArticleCard[];
  readonly isArticleLoading: boolean;
};

const initalState: NewsArticleStateType = {
  article: undefined,
  recommendedArticles: [],
  isArticleLoading: false,
};

@Injectable({ providedIn: 'root' })
export class NewsArticleState {
  readonly #newsArticleState = signalState<NewsArticleStateType>(initalState);
  readonly #newsService = inject(NewsService);
  readonly #localizeState = inject(LocalizeState);
  readonly #routesFullPaths = inject(ROUTES_FULL_PATHS);

  readonly article = this.#newsArticleState.article;
  readonly isArticleLoading = this.#newsArticleState.isArticleLoading;
  readonly recommendedArticles = this.#newsArticleState.recommendedArticles;

  readonly fetchArticle = rxMethod<string>(
    pipe(
      tap(() => patchState(this.#newsArticleState, { isArticleLoading: true })),
      exhaustMap((slug) => {
        return this.#newsService.getNewsArticle(slug).pipe(
          tapResponse({
            next: (article) => patchState(this.#newsArticleState, { article }),
            error: console.error,
            finalize: () => patchState(this.#newsArticleState, { isArticleLoading: false }),
          })
        );
      })
    )
  );

  readonly fetchRecommendedArticles = rxMethod<void>(
    pipe(
      exhaustMap(() =>
        this.#newsService.getFeaturedNews(undefined, 3).pipe(
          tapResponse({
            next: (recommendedArticles) => patchState(this.#newsArticleState, { recommendedArticles }),
            error: () => console.error,
          })
        )
      )
    )
  );

  constructor() {
    this.#localizeState.redirecUrlWhenLangChanged = this.#routesFullPaths.NEWS;
  }
}
