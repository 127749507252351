<vk-media-with-content-block [image]="image()" [video]="video()">
  <div class="hero-block-container">
    <h2 class="hero-block-title">{{ title() }}</h2>
    @if (descriptionBlocks().length > 0) {
      <vk-default-block class="hero-block-description small-text" [blocks]="descriptionBlocks()" />
    }
    <a class="hero-block-btn" [scheme]="'white'" [routerLink]="button().url" vkBtn="secondary">
      @if (button().icon.position === 'left') {
        <vk-icon class="hero-block-icon" [name]="button().icon.name" />
      }
      {{ button().title }}
      @if (button().icon.position === 'right') {
        <vk-icon class="hero-block-icon" [name]="button().icon.name" />
      }
    </a>
  </div>
</vk-media-with-content-block>
