import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, inject, input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'vk-jot-form-embed',
  standalone: true,
  templateUrl: './jot-form-embed.component.html',
  styleUrl: './jot-form-embed.component.scss',
  imports: [CommonModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JotFormEmbedComponent {
  jotId = input.required<string>();
  formHeight = input.required({ transform: (value: number | undefined) => (value ? `${value}px` : this.#defaultFormHeight) });

  readonly #defaultFormHeight = '1300px';
  readonly #sanizer = inject(DomSanitizer);

  readonly jotFormId = computed(() => `jotForm-${this.jotId()}`);
  readonly jotFormSrc = computed(() => this.#sanizer.bypassSecurityTrustResourceUrl(`https://form.jotform.com/${this.jotId()}`));
}
