import { ChangeDetectionStrategy, Component, input } from '@angular/core';

@Component({
  selector: 'vk-kpis-block',
  standalone: true,
  templateUrl: './kpis-block.component.html',
  styleUrl: './kpis-block.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KpisBlockComponent {
  leads = input.required<
    {
      readonly title: string;
      readonly description: string | undefined;
    }[]
  >();
}
