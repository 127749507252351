<iframe
  class="jot-form-iframe"
  #contactUsFormIframe
  [id]="jotFormId()"
  [src]="jotFormSrc()"
  [ngStyle]="{ 'min-height': formHeight() }"
  style="min-width: 100%; max-width: 100%; border: none"
  onload="window.parent.scrollTo(0,0)"
  allowtransparency="true"
  allow="geolocation; microphone; camera; fullscreen"
  frameborder="0"
  scrolling="yes"
>
</iframe>
<script src="https://cdn.jotfor.ms/s/umd/latest/for-form-embed-handler.js"></script>
<script>
  window.jotformEmbedHandler(`iframe[id='${jotFormId()}']`, 'https://form.jotform.com/');
</script>
