import { DOCUMENT } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, inject, input, OnDestroy, Renderer2 } from '@angular/core';

@Component({
  selector: 'vk-tik-tok-embed',
  standalone: true,
  imports: [],
  templateUrl: './tik-tok-embed.component.html',
  styleUrl: './tik-tok-embed.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TikTokEmbedComponent implements AfterViewInit, OnDestroy {
  tikTokVideoId = input<string>('6927634419398266113');
  readonly #renderer = inject(Renderer2);
  readonly #document = inject(DOCUMENT);

  #scriptElement: HTMLScriptElement;

  ngAfterViewInit(): void {
    this.#scriptElement = this.#renderer.createElement('script');
    this.#scriptElement.src = 'https://www.tiktok.com/embed.js';
    this.#scriptElement.async = true;
    this.#renderer.appendChild(this.#document.body, this.#scriptElement);
  }

  ngOnDestroy(): void {
    const scriptElement = this.#document.getElementById('ttEmbedLibScript');
    if (scriptElement) {
      this.#renderer.removeChild(this.#document.body, scriptElement);
    }
    this.#renderer.removeChild(this.#document.body, this.#scriptElement);
  }
}
